import React from 'react'
import OurTeamsComponents from '../components/ourTeams/OurTeams'

export const OurTeams = () => {
  return (
    <>
        <OurTeamsComponents/>
    </>
  )
}
