import React, { useState } from "react";
import { FaAngleDown } from "react-icons/fa";
import Model from "../services/Model";
import ApplyForm from "./ApplyForm";

const CareerCard = ({ el }) => {
  const [toggle, setToggle] = useState(false);
  const [formOpen, setFormOpen] = useState(false);

  const handleToggle = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setToggle((preve) => !preve);
  };

  const handleOpenForm = (e) => {
    setFormOpen(true);
    e.stopPropagation();
  };

  const closeForm = (e) => {
    // e.stopPropagation();
    setFormOpen(false);
  };

  return (
    <>
      <li className="w-full">
        <div
          className="py-4 shadow-lg px-4 flex items-center justify-between border rounded border-gray-400 w-full"
          onClick={handleToggle}
        >
          <div className="">
            <h2 className="text-base lg:text-xl  font-bold ">
              {el.title}{" "}
              {el.active && (
                <span className="px-2 text-sm py-1  bg-red-200 rounded-full">
                  {Boolean(el.active) && "Urgent"}
                </span>
              )}
            </h2>
            <p>{el.location}</p>
          </div>

          <div className="flex items-center justify-center lg:gap-7">
            <div className="p-1 bg-blue-100 border border-blue-900 text-blue-900 rounded-full hidden lg:flex items-center justify-center">
              <span
                className={` ${
                  toggle ? "rotate-180" : "rotate-0"
                } transition-all `}
              >
                <FaAngleDown />
              </span>
            </div>
            <button className="text-white text-xs sm:text-sm whitespace-nowrap bg-blue-800 px-4 rounded-full py-1" onClick={handleOpenForm}>
              Apply Now
            </button>
          </div>
        </div>
        {toggle && (
          <div className="py-4 px-4 border border-t-0 border-gray-400">
            <div className="font-bold">Location</div>
            <p>{el.location}</p>
            <div className="font-bold">Job Description</div>
            {el?.jobDescription[0]?.split('\n').map((el) => {
              return <p key={el}>{el}</p>;
            })}
            <div className="font-bold">Experience</div>
            <p>{el.experience}</p>
            <div className="font-bold">Salary</div>
            <p>{el.salary}</p>
            <div className="font-bold">No. Openings</div>
            <p>{el.noOpening}</p>
          </div>
        )}
      </li>

      {formOpen && (
        <Model onClose={closeForm}>
            <ApplyForm onClick={closeForm} jobDetails={el}/>
        </Model>
      )}
    </>
  );
};

export default CareerCard;
