import React, { useEffect, useState } from "react";
import background from '../../assets/background/background.jpg'
import background2 from '../../assets/background/background2.png'
import background6 from '../../assets/background/background8.jpg'

import background9 from '../../assets/background/background9.jpg'
import background10 from '../../assets/background/background10.jpg'
import background11 from '../../assets/background/background11.jpg'
import { Link } from "react-router-dom";



const Hero = () => {

  return (
    <section className="h-[500px] min-h-fit w-full relative  bg-slate-200" id="hero">


      <div className="absolute w-full h-full  opacity-96  bg-gradient-to-tl  from-slate-200 to-slate-300 bg-opacity-10">
        {/* <img src={background6} className="w-full h-full object-fit" alt="Hero section"/> */}
      </div>

      <div className="absolute w-full h-full flex lg:block justify-center items-center">
        <img src={background9} className="w-full h-full object-fit  hidden md:block" alt="Hero section"/>
        <img src={background11} className=" h-full object-fill w-full min-w-[750px] -mr-16 md:hidden " alt="Hero section"/>
      </div>

     

      <div className="container px-4 mx-auto">
        <div className="relative max-w-md md:max-w-lg lg:max-w-xl">
          <h1 className="text-4xl lg:text-5xl  font-extrabold text-gray-800 pt-6 lg:pt-5">
            Find A Dream Home With<span className="text-blue-900 leading-normal"> Our Expert !!</span>
          </h1>
          {/* <p className="my-2 drop-shadow-xl text-gray-800 max-w-[250px] md:max-w-sm lg:max-w-md text text-base lg:text-lg ">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make </p> */}
          {/* <Link to={"/contact"} className="px-5 py-2 bg-blue-900 text-white rounded font-semibold my-3 inline-block"> Contact us</Link> */}
        </div>
      </div>

      
    </section>
  );
};

export default Hero;
