import React, { useEffect, useState } from "react";
import serviceImage from "../../assets/service2.png";
import serviceImageMobile from "../../assets/service2Mobile.png";
import serviceBackgroundImage from "../../assets/serviceBackground.jpg";
import { HiUserGroup } from "react-icons/hi";
import { MdLocationCity, MdOutlineMoveDown } from "react-icons/md";
import { TbCoinRupee } from "react-icons/tb";
import { FaHome } from "react-icons/fa";
import {AiOutlineAntDesign} from "react-icons/ai"
import Model from "../services/Model";
import EnquiryForm from "../enquiryForm/EnquiryForm";

const ourServiceSection = [
  {
    title: "Profiling & shortlisting",
    desc: `  Our experts work very closely with the customer to find the right property.`,
    icon: <HiUserGroup />,
    button : "Connect with Expert",
    modelTitle : "Connect with House Seekers",
    required :   {
      name : true,
      countryCode : true,
      mobile : true,
      email : true,
      propertyType : false,
      occupationType : false,
      location : false,
      locationofProperty : false,
      address : true,
      sourceAddress : false,
      destinationAddress : false,
      dateTimePicker : false,
      dataPrivacy : true,
    },
    DateTimeON : false,
    EnquiryFormButton : "Connect with Expert"
  },
  {
    title: "Fix meeting and site visit",
    desc: `  Virtual tour in presentation and actual site visit with a dedicated relationship manager.`,
    icon: <MdLocationCity />,
    button : "Book site visit",
    modelTitle : "Connect with House Seekers",
    required :   {
      name : true,
      countryCode : true,
      mobile : true,
      email : true,
      propertyType : false,
      occupationType : false,
      location : false,
      locationofProperty : false,
      address : true,
      sourceAddress : false,
      destinationAddress : false,
      dateTimePicker : true,
      dataPrivacy : true,
    },
    DateTimeON : true,
    EnquiryFormButton : "Connect with Expert"
  },
  {
    title: " Financial assistance",
    desc: `Home loan service through all banks across India and legal advice.`,
    icon: <TbCoinRupee />,
    button : "Connect For Home Loan",
    modelTitle : "Connect For Property Loan",
    location : true,
    required :   {
      name : true,
      countryCode : true,
      mobile : true,
      email : true,
      propertyType : true,
      occupationType : true,
      location : false,
      locationofProperty : false,
      address : true,
      sourceAddress : false,
      destinationAddress : false,
      dateTimePicker : false,
      dataPrivacy : true,
    },
    DateTimeON : false,
    EnquiryFormButton : "Get CALL BACK",
    PropertyType : [
      {
        label : "Residential",
        value : 'residential',
      },
      {
        label : "Commercial",
        value : "commercial"
      },
      {
        label : 'Land',
        value : 'land'
      }
    ],
    OccupationType  : [
      {
        label : 'Salaried',
        value : 'salaried'
      },
      {
        label : 'Businessmen',
        value : 'businessmen'
      }
    ]
  },
  {
    title: "Finalization of dream home",
    desc: `All guidance and support from a dediated manager till possession.`,
    icon: <FaHome />,
    button : "Connect With Expert",
    modelTitle : "Connect with House Seekers",
    required :   {
      name : true,
      countryCode : true,
      mobile : true,
      email : true,
      propertyType : false,
      occupationType : false,
      location : false,
      locationofProperty : false,
      address : true,
      sourceAddress : false,
      destinationAddress : false,
      dateTimePicker : false,
      dataPrivacy : true,
    },
    DateTimeON : false,
    EnquiryFormButton : "GET CALL BACK"
  },
  {
    title: " Move in & Move Out Service",
    desc: `  After possession from builder complete support for all 
  services related to move in & move out.`,
    icon: <MdOutlineMoveDown />,
    button : "Connect With Movers and Packers",
    modelTitle : "Connect With Movers and Packers",
    required :   {
      name : true,
      countryCode : true,
      mobile : true,
      email : true,
      propertyType : false,
      occupationType : false,
      location : false,
      locationofProperty : false,
      address : false,
      sourceAddress : true,
      destinationAddress : true,
      dateTimePicker : true,
      dataPrivacy : true,
    },
    DateTimeON : false,
    EnquiryFormButton : "GET CALL BACK",
    ToFrom : true,
  },
  {
    title: "Interior Designing",
    desc: `From Foundation to furnishings, We style your dream home and office like our own.`,
    icon: <AiOutlineAntDesign />,
    button : "Book Appointment",
    modelTitle : " Connect with Designer ",
    required :   {
      name : true,
      countryCode : true,
      mobile : true,
      email : true,
      propertyType : false,
      occupationType : false,
      location : false,
      locationofProperty : false,
      address : true,
      sourceAddress : false,
      destinationAddress : false,
      dateTimePicker : false,
      dataPrivacy : true,
    },
    DateTimeON : false,
    EnquiryFormButton : "Book Appointment"
  },
];

const whyChooseus = {
  title: `Why to choose House Seekers?`,
  desc: [
    `Shape your dreams into reality by buying a home from an expert real estate consultancy firm- House Seekers. Our team has a meticulous approach to shortlisting the projects for you so that you never regret buying them. And the cherry on the cake is the in-built search algorithm that makes us doubly confident about your needs.`,
    `Our clients vouch for our credibility and transparency. What makes us a standalone firm is our ability to forge relationships with clients at every stage. Whether it is home buying, financing, relocation, or interior decoration, we have got you covered.`,
    `Our USP is our sales professionals who handheld you during the buying process and beyond that. Being in this industry for the last 7 years, we can proudly reinstate that none can understand the customer's nerves the way we do. `,
    `So, leave all your property-related anxieties to us. We promise to empower you with an investment for a long time.`,
  ],
};

const OurServices = () => {
  // [#D9DD68]
  const [blick,setBlick] = useState(0)
  const [pause,setPause] = useState(false)
  const [openEnquiryForm,setopenEnquiryForm] = useState(false)
  const [openEnquiryFormHeading,setOpenEnquiryFormHeading] = useState("Please Share Details to Callback")
  const [enquiryButtonSendUs,setEnquiryButtonSendUs] = useState("Send us")
  const [activeEnquiry,setActiveEnquiry] = useState({})

  useEffect(()=>{
    const animationBlick = ()=>{
      if(blick < ourServiceSection.length - 1){
        setBlick((preve) => preve + 1)
      }else{
        setBlick(0)
      }
    }
    const interval = setInterval(()=>{
      if(!pause){
        animationBlick()
      }
    },7000)

    return ()=>clearInterval(interval)
  },[blick,pause])



  const OpenModel = (object,index)=>{
    setopenEnquiryForm(true)
    setOpenEnquiryFormHeading(object.modelTitle)
    setEnquiryButtonSendUs(object.EnquiryFormButton)
    setActiveEnquiry(object)
  }

  const closeForm = (e)=>{
    e.stopPropagation();
    setopenEnquiryForm(false)
    setOpenEnquiryFormHeading("Please Share Details to Callback")
  }

  return (
    <>
    <section
      id="service"
      className="py-16  text-white  bg-slate-500  relative text-base lg:text-lg"
    >
      <div className="w-full h-full absolute top-0">
        <img
          src={serviceBackgroundImage}
          className="w-full h-full"
          alt="serviceBAckground"
        />
      </div>

      <div className="w-full h-full absolute top-0 bg-black bg-opacity-80">
        {/* <img src={serviceBackgroundImage} className="w-full h-full" alt="serviceBAckground"/> */}
      </div>

      <div className="container px-4 mx-auto relative z-10">
        <h2 className="text-2xl lg:text-3xl font-medium text-center">
          Our <span className="font-extrabold">Services</span>
        </h2>
        <div className="px-10 p-0.5 max-w-[100px] rounded-full bg-blue-500 m-auto mt-2"></div>

        <div className="flex flex-col justify-center items-center lg:items-start lg:flex-row lg:justify-between w-full lg:gap-20 xl:gap-24 mt-16 ">
          <div className="flex-1 px-2">
            <div className="flex flex-col justify-center gap-10 relative">

              <div className="px-1 h-[90%] left-7 bg-blue-600 absolute my-auto "></div>
              {ourServiceSection.map((el, index) => {
                return (
                  <div className="flex items-center gap-4 lg:gap-6 xl:gap-7" onMouseEnter={()=>setBlick(index)} onMouseLeave={()=>setPause(false)}>
                    <div className={`flex relative justify-center items-center min-w-[64px] w-16 h-16 rounded-full  bg-white text-blue-900 border-4 ${blick === index ? "border-yellow-500" : "border-blue-600"}`}>
                      <div className={`absolute -left-5 -top-3 w-5 h-5 bg-blue-600 flex justify-center items-center font-bold rounded-full ${blick === index ? "bg-yellow-500 text-black" : "text-white"}`}>{index + 1}</div>
                      <div className="text-4xl ">{el.icon}</div>
                    </div>
                    <div className={`flex flex-col justify-center  ${blick === index && "text-yellow-500"}`}>
                      <h3 className="font-extrabold text-xl capitalize">
                        {el.title}
                      </h3>
                      <p>
                        {el.desc}  <span className="h-6 block">{blick === index && <button onClick={()=>OpenModel(el,index)} className="border border-yellow-500  px-2 rounded text-sm">{el.button}</button> }</span>
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="flex-1">
            <div className="text-2xl lg:text-3xl font-extrabold md:font-bold drop-shadow-lg mb-2 mt-16 lg:mt-0 text-center lg:text-left">
              {whyChooseus.title}
            </div>
            <div className="px-10 p-0.5 max-w-[200px] rounded-full bg-blue-500 mb-12 m-auto lg:m-0 lg:mb-7"></div>

            <div className="text-slate-200 text-base lg:text-lg flex flex-col gap-8     drop-shadow-md ">
              {whyChooseus.desc.map((el) => {
                return <p>{el}</p>;
              })}
            </div>
          </div>
        </div>
      </div>
    </section>


    { openEnquiryForm && 
        <Model onClose={closeForm}>
          <EnquiryForm 
            onClick={closeForm}
            heading={openEnquiryFormHeading}
            button={enquiryButtonSendUs}
            PropertyType={activeEnquiry.PropertyType || []}
            location={activeEnquiry?.location || false}
            OccupationType={activeEnquiry.OccupationType || []}
            ToFrom={activeEnquiry.ToFrom || false}
            required={activeEnquiry.required || {}}
            DateTimeON={activeEnquiry.DateTimeON || false}
            comingFrom={activeEnquiry?.title}
          />
       </Model>
       }  
    </>
  );
};

export default OurServices;
