import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import sampleImage from "../../assets/testomonial/image.jpg";
import { BiSolidQuoteAltLeft, BiSolidQuoteAltRight } from "react-icons/bi";
import hormonyInfraLogo from  '../../assets/testomonial/houseInfrom.png' 
import {ImQuotesRight,ImQuotesLeft} from "react-icons/im"
import {BiUserCircle} from 'react-icons/bi'
import {TfiAngleRight,TfiAngleLeft} from 'react-icons/tfi'
import axios from 'axios'
import config from "../../config";

const color = ['bg-orange-500','bg-lime-500','bg-cyan-500','bg-indigo-500','bg-pink-500','bg-amber-500','bg-green-500','bg-teal-500']
const Testomonials = () => {
  // const testomonialData = [
  //   {
  //     id: "1",
  //     name: "Mr. Dilip Nimkar",
  //     companyLogo: hormonyInfraLogo,
  //     companyName: "",
  //     distination: "Senior Associate",
  //     img: "",
  //     desc: `I Dilip K Nimkar like to inform u that ,Mr Nishant Sethi from House Seekers India Pvt limited who is Sister Company of Harmony InfraSolutions Pvt Limited is punctual about work given & attentive about work & sincerely giving support to my every question in <strong> Godrej Pune Project </strong>. I am giving rating 10 /10. All Over Experience was Good`,
  //   },
  //   {
  //     id: "2",
  //     name: "Mr. Pratap Jagtap-",
  //     companyLogo: hormonyInfraLogo,
  //     companyName: "",
  //     distination: "Senior Associate",
  //     img: "",
  //     desc: `Mr. Nishant from Harmony Infra Solutions Pvt is helpful executive, He arranged the meeting on 8 Dec with me. He explained about to the details of project structure and amenities of Godrej Forest Grove. I liked project, immediately I started booking process paid booking amount of the Flat and  gave token. I attended allotment event on 19th Jan at Hinjawadi. Mr. Nishant helped for complete the allotment process, I am feeling better with Mr. Nishant as well as <strong> Godrej Forest Grove Pune <strong/>.` 
  //   },
  //   {
  //     id: "3",
  //     name: "Sayan Ghosh",
  //     companyLogo: "",
  //     companyName: "",
  //     distination: "Senior Associate",
  //     img: "",
  //     desc:`Conversation with Siddharth Singh was a good experience. She had provided me with all the information to the best of her knowledge. She also guide me through a simple and professional manner and provide me confidence to invest in <strong> Lodha Palava City</strong>.`
  //   },
  //   {
  //     id: "4",
  //     name: "Swati Prabhu",
  //     companyLogo: "",
  //     companyName: "",
  //     distination: "Teacher",
  //     img: "",
  //     desc:` I am very glad to share my feedback of buying flat in <strong> Lodha </strong> was a great experience .<strong> Mr Ravinder Singh </strong> guided me in a very perfect way to buy a property. He take lots of effort in showing a flat , giving a perfect account person from <strong> Lodha </strong> to do proper transaction. He puts his heart and soul while working. I am very satisfied by buying a flat through <strong> Mr. Nishant Sethi </strong> and I am also thankful to a Company.`
  //   },

  // ];

  const [current,setCurrent] = useState(0)
  const [pause,setPause] = useState(false)


  const [TestimonialData,setTestimonialData]=useState([]);

  const testimonialData=()=>{
    const data=axios.get(config.testimonial.url)
    .then((res)=>setTestimonialData(res.data.data))
    

  }
  useEffect(()=>{
    testimonialData()
  },[])

  //next preve
  const handleCurrent = ()=>{
    if(current < TestimonialData.length - 1){
      setCurrent((preve) => preve + 1)
    }else{
      setCurrent(0)
    }
  }
  const preveButton = ()=>{
      if(current === 0 || current === (TestimonialData.length - 1) ){
          setCurrent(0)
      }else{
        setCurrent((preve) => preve - 1)
      }
  }

  useEffect(()=>{
    const interval = setInterval(()=>{
          if(!pause){
            handleCurrent()
          }
     },7000)

    return () => clearInterval(interval);
  },[current,pause])


  const colorList = [...color,...color,...color,...color,...color,...color,...color] 
  return (
    <section id="testomonials" className="">
      <div className="container m-auto my-16 px-4 ">
        <h2 className="text-2xl lg:text-3xl text-center font-extrabold">
          Testimonials
        </h2>
        <div className="px-10 p-0.5 max-w-[100px] rounded-full bg-blue-800 m-auto mt-2"></div>
     

      <div className="flex mt-2 w-full relative overflow-hidden ">
        
        <div className="h-full hidden lg:flex justify-between items-center absolute w-full text-4xl text-slate-500 ">
          <button className="cursor-pointer z-30" onClick={preveButton}><TfiAngleLeft/></button>
          <button className="cursor-pointer z-30" onClick={handleCurrent}><TfiAngleRight/></button>
        </div>

        {TestimonialData.map((el,index) => {
          return (
            <div className={`min-w-[100%] w-full max-w-[100%]  ${current === index ? "opacity-100" : "opacity-80"}   transition-all duration-1000  text-base lg:text-lg p-2`} style={{transform : `translateX(${-(current * 100)}%)`}}>
              <div className=" shadow-blue-200 pt-5  shadow-2xl min-w-[300px] sm:min-w-[400px] sm:max-w-[550px] md:min-w-[600px] lg:min-w-[650px] w-full bg-white flex-1  flex flex-col justify-between items-center rounded overflow-hidden lg:m-auto m-auto">
                <div className="flex flex-col justify-center  py-2 h-full relative text-center px-2">

                <div className="absolute w-full text-slate-300 text-3xl top-0 left-0 h-full px-3">
                          <span className="absolute top-3 left-3"><ImQuotesLeft/></span>
                          <span className="absolute bottom-3 right-3" ><ImQuotesRight/></span>
                </div>


                   <div className="h-fit">
                      <div className=" mix-blend-multiply grayscale h-16 hidden">
                      {el.companyLogo && <img src={el.companyLogo}  alt="company logo" className="w-16"/> }
                      </div>
                      <div 
                        className="min-h-[150px] px-10 sm:max-w-md md:max-w-lg lg:max-w-xl m-auto pb-7 text-base lg:text-lg"
                        dangerouslySetInnerHTML={{__html: el.feedback}}
                      ></div>
                    </div>
                </div>
                <div className={`min-h-[90px] md:min-h-[80px] ${colorList[index]} flex justify-center items-center flex-col w-full rounded-t-[100%]  relative z-10`}>
                  <div className={`text-5xl text-slate-500  ${colorList[index]} rounded-full -mt-7 p-0.5`}>
                   { <img src={el.image || "https://cdn-icons-png.flaticon.com/512/666/666201.png"}  alt="testomonial" className="w-14 h-14 p-2 rounded-full"/> }
                  </div> 
                  <p className="font-bold">{el.name}</p> 
                  <div className="flex flex-col md:flex-row text-lg -mt-1 text-center">
                    <p>{el.position}</p>
                     
                    <p>{el.companyName && ", "} {el.companyName}</p>
                  </div>
                </div>
            </div>
            </div>
          )
        })}
      </div>

      <div className="mt-5 flex items-center gap-5 justify-center">
        {
          TestimonialData.map((el,index)=>{
            return(
              <div className={`p-2 rounded-full cursor-pointer ${index === current ? "bg-slate-500" : "bg-slate-300"} `} onClick={()=>setCurrent(index)} onMouseEnter={()=>setPause(true)} onMouseLeave={()=>setPause(false)}></div>
            )
          })
        }
      </div>

      </div>
    </section>
  );
};

export default Testomonials;
