import React from 'react'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';



const TooltipCustom = ({top = false,bottom = false,left = false, right = false,children,title}) => {
  return (
    <Tooltip title={title} placement="top" sx={{backgroundColor: "white",}}>
        {children}
    </Tooltip>
  )
}

export default TooltipCustom