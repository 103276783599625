import React from "react";
import aboutImage from "../../assets/about/about.JPG";

const Aboutus = () => {
  return (
    <section id="about" className="bg-gradient-to-r from-green-100 to-yellow-100 p-1">
      <div className=" container my-3 py-16 m-auto px-4   ">
        <h2 className="text-2xl lg:text-3xl  text-center font-extrabold">
          About us
        </h2>
        <div className="px-10 p-0.5 max-w-[100px] rounded-full bg-blue-800 m-auto mt-2"></div>

        <div className="flex flex-col md:flex-row md:justify-between gap-10 mt-14">
        {/* flex-1  */}
          <div className=" text-base lg:text-lg w-full">
          {/* max-w-lg  */}
            <div className=" flex flex-col gap-3 max-w-5xl mx-auto">
              <ol className="list-decimal pl-4">
                <li>
                  <strong>Establishment (2012): </strong> Harmony Infrasolutions was registered in 2012, led by Mr. Sumit Batra and Mr. Aashray Aggarwal. During this period, the company likely focused on its initial vision and services in the real estate sector.
                </li>
                
                <li>
                  <strong>Acquisition (2017):  </strong>In 2017, Mr. Mukesh Batra acquired the company. This acquisition might have brought new opportunities, strategies, and resources to the organization.
                </li>

                <li>
                  <strong>Name Change (April 2020):  </strong>Following the acquisition, the company underwent a rebranding, and its name was changed from Harmony Infrasolutions to House Seekers. 
                </li>
              </ol>

              <p className="my-0">
              As a tech-driven company with a one-stop shop approach, House Seekers appears to be committed to providing a seamless and customer-centric experience for its clients throughout the home-buying process.  
              </p>
              <p className="my-0">
                Since our inception, the company has added a rich portfolio of services to metamorphose the home-buying experience. Our partnership with established real estate honchos like Lodha Group, Piramal Realty, Birla Group, Rustomjee, Hiranandani, Oberoi, etc., speaks volumes about what our clients can expect from us.
              </p>
              <p className="my-0">
              We have a team of dexterous sales professionals who help you to shortlist the property, provide a virtual tour of it and then close the deal. To make the home-buying journey seamless, we also offer refinancing, move-in and move-out, and home styling services. 
              </p>
              <p className="my-0">
              Our presence spans across various regions and abroad, like Mumbai, Pune, Bangalore, Delhi, and Dubai. So, be at peace, because “ Your Home Search Ends here.” 
              </p>
            </div>
          </div>

          {/* <div className=" p-5 md:p-7 rounded drop-shadow-lg shadow-md flex-1 relative overflow-hidden aboutusImage">
            <img src={aboutImage} alt="about" className="w-full" />
          </div> */}
        </div>
      </div>
    </section>
  );
};

export default Aboutus;
