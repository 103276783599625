import React, { useEffect, useLayoutEffect, useState } from 'react'
import {LiaAngleRightSolid,LiaAngleLeftSolid} from "react-icons/lia"
import { Link, useParams} from 'react-router-dom'
import config from '../../config'
import { Breadcrumbs, CircularProgress, LinearProgress } from "@mui/material";
import { GrClose ,GrUserExpert} from "react-icons/gr";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import HomeIcon from '@mui/icons-material/Home';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import GrainIcon from '@mui/icons-material/Grain';
import Typography from '@mui/material/Typography';

const EventImage = () => {
    const [activeImage,setActiveImage] = useState(0)

   const eventImageList = [
    {
        id : "1"
    },
    {
        id : "2"
    },
    {
        id : "3"
    },
    {
        id : "4"
    },
    {
        id : "5"
    },
    {
        id : "6"
    },
    {
        id : "7"
    },
   ] 

    const params = useParams()
    const [loading,setLoading] = useState(false)
    const [getData,setGetData] = useState([])
    const [categoryName,setCateogryName] = useState({})
    const [subCategoryName,setSubCateogryName] = useState({})

    
  
    console.log(params)

    const fetchData = async () => {
      setLoading(true)
      const res = await fetch(config.gallery.galleryImage.URL);
      const dataResponse = await res.json();
      const filterDataResponse = dataResponse?.data.filter(el => el.subCategoryImage._id === params.subCategory)
      setGetData(filterDataResponse);
      setLoading(false)
  };
  useEffect(()=>{
      fetchData()
  },[])


   const handleNext = ()=>{
        if(activeImage < eventImageList.length -1){
            setActiveImage((preve) => preve + 1)
        }
        
   }
   const handlePreve = ()=>{
        if(activeImage > 0){
            setActiveImage((preve) => preve - 1)
        }
   }


   //full screen
   const [fullScreen,setFullScreen] = useState(false)
   const [fullScreenData,setFullScreenData] = useState({})

   const handleOpenFullScreen = (el)=>{
        setFullScreen(true)
        setFullScreenData(el)
   }
   const handleCloseFullScreen = ()=>{
    setFullScreen(false)
    setFullScreenData({})
   }


   const images = getData.map((el,index)=>{
    return {
        original: el?.images,
        thumbnail : el?.images
    }
   })


   const [mobile,setMobile] = useState(0)
   useLayoutEffect(()=>{
      let windowWidth = window.innerWidth
      window.addEventListener("resize",()=>{
          windowWidth = window.innerWidth
      })
      setMobile(windowWidth)
    },[mobile])
  return (
    <>
    <div className="container mx-auto my-10">
        <h2 className="text-2xl lg:text-3xl font-extrabold text-center mt-3">
          {getData[0]?.subCategoryImage?.subCategoryImage}
        </h2>
        <div className="px-10 p-0.5 max-w-[100px] rounded-full bg-blue-800 mt-2 mb-12 mx-auto"></div>
       
        <div className="capitalize px-2 my-1 text-xs ImageDisplaySection">
            <div role="presentation">
      <Breadcrumbs aria-label="breadcrumb" maxItems={mobile >= 400 ? 8 : 2 }>
          <Link
            underline="hover"
            sx={{ display: 'flex', alignItems: 'center', fontSize : 'inherit' }}
            color="inherit"
            to="/gallery"
          >
            <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              Gallery
          </Link>
        <Link
          underline="hover"
          sx={{ display: 'flex', alignItems: 'center', fontSize : 'inherit' }}
          color="inherit"
          to={`/gallery/${params.imageCategory}`} 
        >
          <WhatshotIcon sx={{ mr: 0.5 }} fontSize="inherit" />
            {getData[0]?.categoryImage?.categoryImage}
        </Link>
        <Typography
          sx={{ display: 'flex', alignItems: 'center' ,fontSize : 'inherit'}}
          color="text.primary"
        >
          <GrainIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          {getData[0]?.subCategoryImage?.subCategoryImage}
        </Typography>
      </Breadcrumbs>
            </div>
        </div>

        <div className="my-5">
        <div className='flex flex-wrap justify-center lg:justify-center gap-3 relative px-4'>

            {
                loading ? (
                    <div className="h-54 w-full flex justify-center items-center text-blue-800">
                    <CircularProgress color="primary" size={80} Type="primary"/>
                    </div>
                )
                :
                (
                    <div className='w-full'>
                        <ImageGallery items={images}  additionalClass='ImageGallery' />;
                    </div>
                )
            }

        </div>



        </div>
      </div>
    </>
   
  )
}

export default EventImage