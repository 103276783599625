import { useState } from 'react'

const useToggle = (value = false) => {
    const [toggle,setToggle] = useState(value)

    const handleToggle = ()=>{
        setToggle(preve => !preve)
    }

    return [toggle,handleToggle]
}

export default useToggle