import React, { useEffect, useState } from 'react'
import Modal from '@mui/material/Modal';

const Model = ({children,onClose}) => {
  const handleStop = (e)=>{
      e.stopPropagation()
  }
  const [overflow,setOverFlow] = useState(false)

 
  return (
      <div className='fixed top-0 bottom-0  left-0  right-0 bg-slate-500 min-w-screen bg-opacity-50 z-50 overflow-y-hidden overflow-x-hidden' onClick={onClose}>
            <div className='z-50 px-2 relative w-screen h-screen overflow-auto flex md:block justify-center items-center'>
              <div className='w-fit h-fit my-30 m-auto relative my-4 lg:my-8' onClick={handleStop}>
                {children}
              </div>
            </div>
      </div>
  )
}

export default Model