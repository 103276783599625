import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import config from '../../config'
import { Breadcrumbs, CircularProgress, LinearProgress } from "@mui/material";
import {LiaAngleRightSolid} from 'react-icons/lia'
import HomeIcon from '@mui/icons-material/Home';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import GrainIcon from '@mui/icons-material/Grain';
import Typography from '@mui/material/Typography';

const EventYears = () => {
    const numberYears = [
        "2019",
        "2020",
        "2021",
        "2022",
        "2023",
    ]

    const params = useParams()
    const [loading,setLoading] = useState(false)
    const [getData,setGetData] = useState([])
    const [categoryName,setCateogryName] = useState({})
    const [imageLen,setImageLen] = useState([])
  
    const fetchData = async () => {
      setLoading(true)
      const res = await fetch(config.gallery.subCategoryImage.URL);
      const dataResponse = await res.json();
      console.log("subCategory",dataResponse)
      const filterDataResponse = dataResponse?.data.filter(el => el.category._id === params.imageCategory)
      setGetData(filterDataResponse);
      setLoading(false)
  };
  useEffect(()=>{
      fetchData()
  },[])

  const fetchDataImage = async () => {
    setLoading(true)
    const res = await fetch(config.gallery.galleryImage.URL);
    const dataResponse = await res.json();
    setImageLen(dataResponse?.data)
    setLoading(false)
};
useEffect(()=>{
  fetchDataImage()
},[])

  return (
    <>
    <div className="container mx-auto my-10">
        <h2 className="text-2xl lg:text-3xl font-extrabold text-center mt-3">
                {getData[0]?.category?.categoryImage}
        </h2>
        <div className="px-10 p-0.5 max-w-[100px] rounded-full bg-blue-800 mt-2 mb-12 mx-auto"></div>
        
        <div className="capitalize px-2 my-1"> 
          <div role="presentation">
            <Breadcrumbs aria-label="breadcrumb">
                <Link
                  underline="hover"
                  sx={{ display: 'flex', alignItems: 'center' }}
                  color="inherit"
                  to="/gallery"
                >
                  <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                    Gallery
                </Link>
              <Link
                underline="hover"
                sx={{ display: 'flex', alignItems: 'center' }}
                color="inherit"
                to={`/gallery/${params.imageCategory}`} 
              >
                <WhatshotIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                {getData[0]?.category?.categoryImage}
              </Link>
            </Breadcrumbs>
          </div>
        </div>

        <div className="my-5">
            <div className='flex  flex-wrap gap-4 md:gap-9 justify-center'>
                { !loading && ( 
                getData.map(el =>{
                    console.log(el)
                    return(
                        <Link to={el._id} className=' w-52 min-h-[120px] flex justify-center items-center flex-col rounded font-bold relative'>
                        { el?.thumnail ?  <img src={el?.thumnail} className='w-full h-full bg-opacity-50 shadow-xl' loading='lazy'/> : <div className='bg-slate-300 shadow-xl min-h-[120px]'></div>  }  
                            <p className='capitalize pt-2'>{el.subCategoryImage} ({imageLen.filter(el1 => el1?.subCategoryImage?._id == el?._id ).length})</p>
                        </Link>
                    )
                    }))
                }
                {   loading && (
                        <div className="h-54 w-full flex justify-center items-center text-blue-800">
                        <CircularProgress color="primary" size={80} Type="primary"/>
                    </div>
                    )    
                }
    </div>
        </div>
      </div>
    </>
   
  )
}

export default EventYears

