import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { GrClose } from "react-icons/gr";
import triangle from "../../assets/triangle.png";
import Autocomplete, { createFilterOptions }  from "@mui/material/Autocomplete";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import AllCityies from "./allcity";
import axios from "axios";
import config from "../../config";
import { toast } from "react-toastify";
import { FaWhatsapp } from "react-icons/fa";

const theme = createTheme({
  palette: {
    primary: {
      main: "#1e40af",
    },
  },
});

const locationAddresss = [
  {
    locationName: "Mumbai",
    address:
      "242/1, Silver Castle Building, Fathima Church Road, Majiwada, Thane, Mumbai- 400601.",
    googleMap:
      "https://www.google.com/maps/place/House+Seekers/@19.2143905,72.9799356,15z/data=!4m6!3m5!1s0x3be7b902377eb7f3:0x769f62ec4b5dc54e!8m2!3d19.2143905!4d72.9799356!16s%2Fg%2F11gjnb79cq?hl=en&entry=ttu",
  },
  // {
  //   locationName: "Andheri Branch",
  //   address:
  //     "Mumbai Co-Working ,401 B, Annapurna, Near Adarsh Nagar,Oshiwara,Off New Link Road, Andheri W,Mumbai Maharashtra 400053,India.",
  //   googleMap:
  //     "https://www.google.com/maps/place/House+Seekers+Andheri/@19.1462676,72.8302929,15z/data=!4m6!3m5!1s0x3be7b7beef4ae7bd:0x80b4c4a55066b9cb!8m2!3d19.1462676!4d72.8302929!16s%2Fg%2F11ss6ytf8l?hl=en&entry=ttu",
  // },
  {
    locationName: "Bangalore",
    address:
      "Novel Tech Park, 2nd floor, 46/4, Hosur Rd, H.S, R Extension, Bengaluru, Karnataka 560068.",
    googleMap:
      "https://www.google.com/maps/place/House+Seekers+Bangalore/@12.8904416,77.6395876,15z/data=!4m2!3m1!1s0x0:0x2816ada9330b7a5b?sa=X&ved=2ahUKEwidtKHU1Or_AhXaUGwGHSajA5QQ_BJ6BAguEAA&ved=2ahUKEwidtKHU1Or_AhXaUGwGHSajA5QQ_BJ6BAg_EAk",
  },
  {
    locationName: "Pune",
    address:
      "Coworkista Co- working Spaces, Metropolis lane, Balewadi High St, Baner, Pune, Maharashtra 411045",
    googleMap:
      "https://www.google.com/search?gs_ssp=eJwFwUsKgCAUAEDaBt3BFq3zU2odoUuE72kikYZJefxm2m70I2MeC1c7AG3WgVYByJEJB4s-hIJlpVVbtNqhljBzVJPc-pJDegimL-UzRE-e26Ajb7hMJNF4k3-p7Bx0&q=trios+coworking+space+viman+nagar&rlz=1C1ONGR_enIN1008IN1008&oq=trios+co+working+&aqs=chrome.5.69i57j0i10i512l4j46i10i175i199i512.7253j0j7&sourceid=chrome&ie=UTF-8",
  },
  {
    locationName: "NCR",
    address:
      "#3393, 3rd Floor, South Patel Nagar, Adjacent Jaypee Siddharth Hotel, New Delhi – 110008.",
    googleMap:
      "https://www.google.com/maps/place/Hotel+Siddhartha/@28.6544026,77.1532226,15.25z/data=!4m10!1m2!2m1!1ssiddharth+hotel+delhi+patel+nagar!3m6!1s0x390d03c7b764eff9:0xc5f71de6f53d1f1b!8m2!3d28.6554182!4d77.16462!15sCiFzaWRkaGFydGggaG90ZWwgZGVsaGkgcGF0ZWwgbmFnYXKSAQVob3RlbOABAA!16s%2Fg%2F11k9ks3g8h?entry=ttu",
  },
];

const Contactus = ({ onClick }) => {
  const [data, setData] = useState({
    name: "",
    email: "",
    location: "",
    message: "",
    mobile : "",
    dataPrivacy : false,
    comingFrom : "Contact Form"
  });

  const handleOnChange = (e) => {
    setData((preve) => {
      return {
        ...preve,
        [e.target.name]: e.target.value,
      };
    });
  };

  const handleSubmit = async(e) => {
    e.preventDefault();

    const res = await axios.post(config.enquiry.url,data)
    console.log("contact form",res.data)
    
    if(res.data.error){
      toast.error(res.data.message)
    }else{
      toast.success(res.data.message)
      setData({
        name: "",
        email: "",
        location: "",
        message: "",
        mobile : "",
        dataPrivacy : false,
      })
    }
    console.log(data);
  };


  const [countryCityName,setCountryCityName] = useState([])
  const [searchOption,setSearchOption] = useState([])
  useEffect(()=>{
    const list =   AllCityies.map((el,index)=>{
      return{
        ...el,
        label : el.city,
        value : el.city.toLowerCase(),
        option : el.city
      }
    })
    setCountryCityName(list)
  },[])
  const filter = createFilterOptions();
  const filterContryCityName = (value)=>{
    const data = countryCityName.filter(el => el.value.includes(value.toLowerCase()))
    setSearchOption(data)
  }

  return (
    <section id="contact " className="w-full overflow-hidden relative">
      <div className="container mx-auto my-10 px-4 relative z-10">
        <div className="">
          <h2 className="text-2xl lg:text-3xl font-extrabold text-center">
            Contact us
          </h2>
          <div className="px-10 p-0.5 max-w-[100px] rounded-full bg-blue-800 mt-2 mb-12 mx-auto"></div>

          <div className="flex flex-col lg:flex-row ">
            <div className="flex-1  mx-auto">
              <div className=" min-w-[280px] md:min-w-[400px] lg:min-w-[500px]  mx-auto">
                <form
                  className="max-w-xl flex flex-col gap-5 w-full min-w-[300px] bg-white py-8 px-6 rounded-md mx-auto"
                  onSubmit={handleSubmit}
                >
                  <ThemeProvider theme={theme}>
                    <TextField
                      type="text"
                      id="name"
                      label="Name"
                      variant="outlined"
                      className="w-full bg-white"
                     size="standard"
                      color="primary"
                      name="name"
                      onChange={handleOnChange}
                      required
                      // helperText="Please enter your name"
                    />
                    <TextField
                      type="text"
                      id="text-basic"
                      label="Mobile"
                      variant="outlined"
                      className="w-full bg-white"
                     size="standard"
                      name="mobile"
                      onChange={handleOnChange}
                      required
                      // helperText="Please enter your name"
                    />
                      <TextField
                      type="text"
                      id="email"
                      label="Email"
                      variant="outlined"
                      className="w-full bg-white"
                      size="standard"
                      color="primary"
                      name="name"
                      onChange={handleOnChange}
                      required
                      // helperText="Please enter your email"
                    />
                    {/* <FormControl
                      required
                      sx={{ minWidth: 120, position: "relative" }}
                    >
                      <InputLabel id="demo-simple-select-required-label">
                        Location
                      </InputLabel>
                      <Select
                        labelId="location"
                        id="select Location"
                        name="location"
                        value={data.location}
                        label="Location"
                        onChange={handleOnChange}
                        className="bg-white"
                      >
                        <MenuItem value="">
                          <em>Select Location</em>
                        </MenuItem>
                        <MenuItem value={10}>Mumbai</MenuItem>
                        <MenuItem value={20}>Pune</MenuItem>
                        <MenuItem value={30}>Nasik</MenuItem>
                      </Select>
                      <FormHelperText>Required</FormHelperText>
                    </FormControl> */}

                    {/* <Autocomplete
                      disablePortal
                      id="location-search"
                      value={data?.location?.value}
                      options={searchOption}
                      filterOptions={(options, params) => {
                        console.log(options,params)
                        const filtered = filter(options, params);
                        const { inputValue } = params;
                        // Suggest the creation of a new value
                        const isExisting = options.some((option) => inputValue === option.value);
                        if (inputValue !== '' && !isExisting) {
                          filtered.push({
                            // inputValue,
                            label: inputValue.split("")[0].toLowerCase()+inputValue.slice(1),
                            value: inputValue,
                            option : `Add ${inputValue}`,
                          });
                        }
                        return filtered;
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Location"
                          labelId="location"
                          id="select Location"
                          name="location"
                          className="bg-white"
                          onChange={(e)=>{
                            filterContryCityName(e.target.value)
                            console.log(e.target.value)
                          }}
                        />
                      )}
                      onChange={(event, newValue) => {
      
                        if (typeof newValue === 'string') {
                          setData((preve)=>{
                            return{
                              ...preve,
                              location: newValue,
                            }
                          });
                        } else if (newValue && newValue.inputValue) {
                          // Create a new value from the user input
                          setData((preve)=>{
                            console.log("new valueDisplay",newValue)
                            return{
                              ...preve,
                              location: newValue,
                            }
                          });
                        } else {
                          setData((preve)=>{
                            return{
                              ...preve,
                              location: newValue,
                            }
                          });
                        }
                      }}
                      renderOption={(props, option) => <li {...props}>{option.option}</li>}
                    /> */}

                    <TextField
                      type="text"
                      id="message-basic"
                      label="Message"
                      variant="outlined"
                      className="w-full  bg-white"
                      size="standard"
                      multiline
                      rows={2}
                      name="message"
                      onChange={handleOnChange}
                      required

                      // helperText="Please enter your name"
                    />

              <FormGroup >
                <FormControlLabel  control={
                <>
                <Checkbox name="dataPrivacy" onChange={(e)=>{
                  setData((preve)=>{
                    return{
                      ...preve,
                      dataPrivacy : e.target.checked
                    }
                  })
                }} required/>
                 <Typography component={'label'} id="dataPrivacy" fontSize={14} sx={{display : "flex", flexWrap : 'wrap', alignItems : "center", whiteSpace : 'nowrap', margin : '0' }}>
              You can connect me Via call, on <span className="mx-1 bg-green-600 text-white rounded-full text-lg"><FaWhatsapp/></span> or email.
            </Typography>
                </>
                }  
                className="formCheckboxLable text-gray-800" 
                />
                
            </FormGroup>

                  </ThemeProvider>
                  <button className="px-4 py-2 bg-blue-900 text-white font-medium tracking-wide rounded">
                    Send us
                  </button>
                </form>
              </div>
            </div>
            <div className="flex-1  mt-5  lg:mt-0 relative ">
              <h2 className="text-sm text-center md:text-base lg:text-lg font-extrabold pb-2">HARMONY INFRASOLUTIONS PRIVATE LIMITED</h2>
              <div className="flex h-fit flex-wrap gap-3  relative z-10 p-2 lg:p-0 justify-center">
                {locationAddresss.map((el, index) => {
                  return (
                    <div className="w-full  flex flex-col max-w-sm lg:max-w-[300px] h-40 max-h-[160px] border-1 contactShadow p-3 rounded-md bg-white">
                      <div className="w-full">
                        <div className=" font-bold text-base md:text-lg">
                          {el.locationName}
                        </div>
                        <div className="text-sm md:text-base">{el.address}</div>
                        {/* <a
                            href={el.googleMap}
                            className="px-2 py-1 text-sm w-24 rounded bg-slate-800 mt-2 block"
                          >
                            Google Map
                          </a> */}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="absolute top-0 w-full h-full opacity-10">
        <img src={triangle} className="w-full h-full" />
      </div>
    </section>
  );
};

export default Contactus;
