const config = {
    backendServer_baseURL : "https://api.houseseekers.in/api",
    enquiry : {
        method : "POST",
        url : "https://api.houseseekers.in/api/enquiry"
    },
    career : {
        method : "GET",
        url : "https://api.houseseekers.in/api/career"
    },
    teams : {
        method : "GET",
        url : "https://api.houseseekers.in/api/teams"
    },
    testimonial : {
        method : "GET",
        url : "https://api.houseseekers.in/api/testimonial"
    },
    upload : {
        UploadFile : "https://store.houseseekers.in/api/upload",
    },
    applyjob : {
        POST : "POST",
        GET : "GET",
        url : "https://api.houseseekers.in/api/applyjob"
    },
    galleryImageCategory : {
        method : "GET",
        url : "https://api.houseseekers.in/api/imageCategory"
    },
    gallerySubCategory : {
        method : "GET",
        url : "https://api.houseseekers.in/api/subCategoryImage"
    },
    galleryImage : {
        method : "GET",
        url : "https://api.houseseekers.in/api/galleryImage"
    },
    gallery : {
        imageCategory : {
            URL : "https://api.houseseekers.in/api/imageCategory",
            CREATE : "POST",
            DELETE : "DELETE",
            UPDATE : "PUT",
            GET : "GET",
        },
        subCategoryImage  : {
            URL : "https://api.houseseekers.in/api/subCategoryImage",
            CREATE : "POST",
            DELETE : "DELETE",
            UPDATE : "PUT",
            GET : "GET",
        },
        galleryImage  : {
            URL : "https://api.houseseekers.in/api/galleryImage",
            CREATE : "POST",
            DELETE : "DELETE",
            UPDATE : "PUT",
            GET : "GET",
        },

    },
}

export default config
