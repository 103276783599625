import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import Autocomplete, { createFilterOptions }  from "@mui/material/Autocomplete";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import React, { useEffect, useRef, useState } from "react";
import { GrClose ,GrUserExpert} from "react-icons/gr";
import config from "../../config";
import axios from "axios";
import {AiOutlineFileProtect} from 'react-icons/ai'
import {RiMapPinUserFill} from 'react-icons/ri'
import {HiOutlineCurrencyRupee} from "react-icons/hi"
import {HiMiniUserGroup} from  'react-icons/hi2'
import ReactPhoneInput from 'react-phone-input-material-ui';
import countryCode from "../services/countryCode";
import AllCityies from "../contactus/allcity";
import { FaWhatsapp } from "react-icons/fa";



import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { toast } from "react-toastify";

const theme = createTheme({
  palette: {
    primary: {
      main: "#1e40af",
    },
  },
});


const iconDown = [
  {
    icons : <AiOutlineFileProtect/>,
    name : "Assured Privacy"
  },
  {
    icons : <HiMiniUserGroup/>,
    name : "Expert Advice"
  },
  {
    icons : <RiMapPinUserFill/>,
    name : "Free Site Visit"
  },
  {
    icons : <HiOutlineCurrencyRupee/>,
    name : "Best Price Guarantee"
  }
]


const EnquiryForm = ({onClick,heading = " Please Share Details to Callback",button="Send us",PropertyType,location = false,OccupationType,ToFrom = false,required,address = true,DateTimeON = false,comingFrom = ""}) => {
  const [data, setData] = useState({
    name: null,
    email: null,
    location: null,
    message: null,
    mobile : null,
    countryCode: "+91",
    dataPrivacy : false,
    comingFrom : comingFrom
  });
  const closeRef = useRef()
  const handleOnChange = (e) => {
    setData((preve) => {
      return {
        ...preve,
        [e.target.name]: e.target.value,
      };
    });
  };

  const handleSubmit = async(e) => {
    e.preventDefault();
    const {name,mobile, email} = data

    console.log(data)

    if(name && email && mobile ){
      console.log("data before",data)
      const res = await fetch(config.enquiry.url,{
        method : config.enquiry.method,
        // mode: "no-cors",
        headers : {
          "Content-Type" :  "application/json",
          // "Access-Control-Allow-Origin" : "*"
        },
        body : JSON.stringify(data)
      })
      const dataResponse = await res.json()
      console.log(dataResponse)
      if(dataResponse.success){
        closeRef.current.click()
        toast.success(dataResponse.message)
        console.log("data after",data)
        console.log("data json",JSON.stringify(data))
      }
    }

  };


  const [countryCityName,setCountryCityName] = useState([])
  const [searchOption,setSearchOption] = useState([])
  useEffect(()=>{
    const list =   AllCityies.map((el,index)=>{
      return{
        ...el,
        label : el.city,
        value : el.city.toLowerCase(),
        option : el.city
      }
    })
    setCountryCityName(list)
  },[])
  const filter = createFilterOptions();
  const filterContryCityName = (value)=>{
    const data = countryCityName.filter(el => el.value.includes(value.toLowerCase()))
    setSearchOption(data)
  }

  return (
    <div className="bg-white p-4 lg:p-5 box-border rounded-lg relative">
      <div className="ml-auto w-fit relative">
        <span
          className="text-xl text-slate-400 cursor-pointer"
          onClick={onClick}
          ref={closeRef}
        >
          <GrClose />
        </span>
      </div>
      <h2 className="text-lg lg:text-xl  text-center font-extrabold">
        {heading}
      </h2>
      <div className="px-10 p-0.5 max-w-[100px] rounded-full bg-blue-800 mx-auto mb-7 mt-2"></div>
      <form
        className="max-w-sm flex  flex-wrap  gap-3 w-full m-auto min-w-[300px]"
        onSubmit={handleSubmit}
      >
        <ThemeProvider theme={theme}>
          <TextField
            type="text"
            id="name"
            label="Name"
            variant="outlined"
            className="w-full outline-red-800 border-red-800 hover:outline-blue-600"
            size="small"
            color="primary"
            name="name"
            onChange={handleOnChange}
            required={required.name}
          />
          
          <div className="flex items-center gap-3 w-full">
          <FormControl required sx={{ minWidth: 90, position: "relative", width : "" }}>
            <InputLabel id="demo-simple-select-required-label" size="small">
              code
            </InputLabel>
            <Select
              labelId="countryCode"
              id="countryCode"
              name="countryCode"
              value={data.countryCode}
              label="code"
              size="small"
              onChange={handleOnChange}
              required={required.countryCode}
            >
              {
                countryCode.map((el,index)=>{
                  return(
                    <MenuItem value={"+"+el.code}>+{el.code} {el.country}</MenuItem>
                  )
                })
              }
            </Select>
            {/* <FormHelperText>Required</FormHelperText> */}
          </FormControl>

          <TextField
            type="number"
            id="text-basic"
            label="Mobile"
            variant="outlined"
            className="w-full outline-red-800 border-red-800 hover:outline-blue-600"
            size="small"
            name="mobile"
            onChange={(e)=>{
              setData((preve) => {
                return {
                  ...preve,
                  mobile : parseInt(e.target.value),
                };
              });
            }}
            inputProps={{ maxlength : "10", size : "10" }}
            required={required.mobile}
          />
          </div>
          <TextField
            type="email"  
            id="email"
            label="Email"
            variant="outlined"
            className="w-full outline-red-800 border-red-800 hover:outline-blue-600"
            size="small"
            name="email"
            onChange={handleOnChange}
            required={required.email}
          />


{ PropertyType.length !== 0 && (
    
            <div className="flex gap-3 w-full">

          {
            PropertyType.length !== 0  && (
              <FormControl required sx={{ minWidth: 120, position: "relative", width : "100%" }}>
              <InputLabel id="demo-simple-select-required-label" size="small">
                Property Type
            </InputLabel>
            <Select
              labelId="PropertyType"
              id="PropertyType"
              name="propertyType"
              value={data?.propertyType}
              label="Property Type"
              size="small"
              onChange={handleOnChange}
              required={required.propertyType}
            >
              <MenuItem value="">
                <em>Select property type</em>
              </MenuItem>
              {
                PropertyType?.map((el,index)=>{
                  return(
                    <MenuItem value={el.value}>{el.label}</MenuItem>
                  )
                })
              }
            </Select>
     
          </FormControl>
             )
          }

          {
            OccupationType.length !== 0   && (
              <FormControl required sx={{ minWidth: 120, position: "relative", width : "100%" }}>
            <InputLabel id="demo-simple-select-required-label" size="small">
                Occupation Type
            </InputLabel>
            <Select
              labelId="occupationType"
              id="occupationType"
              name="occupationType"
              value={data?.occupationType}
              label="Occupation Type"
              size="small"
              onChange={handleOnChange}
              required={required.occupationType}
            >
              <MenuItem value="">
                <em>Select occupation type</em>
              </MenuItem>
              {
                OccupationType?.map((el,index)=>{
                  return(
                    <MenuItem value={el.value}>{el.label}</MenuItem>
                  )
                })
              }
            </Select>
     
          </FormControl>
             )
          }
        </div>

)}
          {
            location && (
              <Autocomplete
                      disablePortal
                      id="location-search"
                      className="w-full"
                      size="small"
                      value={data?.location?.value}
                      options={searchOption}
                      filterOptions={(options, params) => {
                        const filtered = filter(options, params);
                        const { inputValue } = params;
                        // Suggest the creation of a new value
                        const isExisting = options.some((option) => inputValue === option.value);
                        if (inputValue !== '' && !isExisting) {
                          filtered.push({
                            // inputValue,
                            label: inputValue.split("")[0].toLowerCase()+inputValue.slice(1),
                            value: inputValue,
                            option : `Add ${inputValue}`,
                          });
                        }
                        return filtered;
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Location of Property"
                          labelId="location"
                          id="select Location"
                          name="locationofProperty"
                          className="bg-white w-full"
                          required={required.locationofProperty}
                          onChange={(e)=>{
                            filterContryCityName(e.target.value)
                            console.log(e.target.value)
                          }}
                        />
                      )}
                      onChange={(event, newValue) => {
      
                        if (typeof newValue === 'string') {
                          setData((preve)=>{
                            return{
                              ...preve,
                              location: newValue,
                            }
                          });
                        } else if (newValue && newValue.inputValue) {
                          // Create a new value from the user input
                          setData((preve)=>{
                            console.log("new valueDisplay",newValue)
                            return{
                              ...preve,
                              location: newValue,
                            }
                          });
                        } else {
                          setData((preve)=>{
                            return{
                              ...preve,
                              location: newValue,
                            }
                          });
                        }
                      }}
                      renderOption={(props, option) => <li {...props}>{option.option}</li>}
                    />
            )
          }
          {
            !location && !ToFrom && address && (
              <TextField
            type="text"
            id="address"
            label="Address"
            variant="outlined"
            className="w-full outline-red-800 border-red-800 hover:outline-blue-600"
            size="small"
            multiline
            rows={1}
            name="address"
            onChange={handleOnChange}
            required={required.address}
          />
            )
          }
          {
            ToFrom && (
              <>
                <TextField
            type="text"
            id="SourceAddress"
            label="Source Address"
            variant="outlined"
            className="w-full outline-red-800 border-red-800 hover:outline-blue-600"
            size="small"
            name="sourceAddress"
            onChange={handleOnChange}
            required={required.sourceAddress}
          />
          <TextField
            type="text"
            id="destinationAddress"
            label="Destination Address"
            variant="outlined"
            className="w-full outline-red-800 border-red-800 hover:outline-blue-600"
            size="small"
            name="destinationAddress"
            onChange={handleOnChange}
            required={required.destinationAddress}
          />
              </>
            )
          }

          {  (ToFrom || DateTimeON ) && (
              <LocalizationProvider dateAdapter={AdapterMoment} size="small" >
                <div className="w-full" >
                <DateTimePicker
                  slotProps={{ textField: { size: 'small', required : required.dateTimePicker } }}
                  label="Date and Time"
                  className="w-full"
                  sx={{width : "100%"}}
                  size="small"
                  variant="outlined"
                  viewRenderers={{
                    hours: renderTimeViewClock,
                    minutes: renderTimeViewClock,
                    seconds: renderTimeViewClock,
                  }}
                  onChange={(e)=> {
                    setData((preve) => {
                      return {
                        ...preve,
                        dateTimePicker : e,
                      };
                    });
                  }}
                 
              />
              </div>
               </LocalizationProvider>
          )
                
      
          }
          
          <div className="w-full text-xs">
          <FormGroup sx={{display : 'flex', margin : 0}}>
            <FormControlLabel  control={
              <>
                <Checkbox name="dataPrivacy" id="dataPrivacy" onChange={(e)=>{
              setData((preve)=>{
                return{
                  ...preve,
                  dataPrivacy : e.target.checked
                }
              })
            }}  required={required.dataPrivacy}/>
            <Typography component={'label'} id="dataPrivacy" fontSize={14} sx={{display : "flex", flexWrap : 'wrap', alignItems : "center", whiteSpace : 'nowrap', margin : '0' }}>
              You can connect me Via call, on <span className="mx-1 bg-green-600 text-white rounded-full text-lg"><FaWhatsapp/></span> or email.
            </Typography>
              </>
            }  className=" text-gray-800" />
            
          </FormGroup>
          </div>
        </ThemeProvider>
        
        
  
          <button className="px-6  py-2 bg-blue-900 text-white font-medium tracking-wide rounded mx-auto">
            {button}
          </button>
      </form>

        <div className="mt-5  relative max-w-sm w-full">
          <h3 className="font-bold text-center mb-2">Our Commitment</h3>
          <div className="flex items-center justify-between w-full">
              {
                iconDown.map((el,index)=>{
                  return(
                    <div className="flex flex-col items-center">
                      <div className="text-2xl text-blue-800  lg:my-2">
                        {el.icons}
                      </div>
                      <div className="capitalize text-center  text-sm">
                          {el.name}
                      </div>
                    </div>
                  )
                })
              }
          </div>
        </div>
    </div>
  );
};

export default EnquiryForm;
