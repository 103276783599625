import {
  Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography
  } from "@mui/material";
  import { createTheme, ThemeProvider } from "@mui/material/styles";
  import { FaWhatsapp } from "react-icons/fa";
import axios from "axios";
  import React, { useRef, useState } from "react";
import { AlignRight } from "react-feather";
  import { GrClose } from "react-icons/gr";
  import { IoMdCloudUpload } from "react-icons/io"
import config from "../../config";
import countryCode from "../services/countryCode";
import {  toast } from 'react-toastify';
import CircularLoading from "../Loading/CircularLoading"

  const theme = createTheme({
    palette: {
      primary: {
        main: "#1e40af",
      },
    },
  });

const ApplyForm = ({onClick,jobDetails}) => {
    const [data, setData] = useState({
        name: "",
        email: "",
        mobile : "",
        file : "",
        dataPrivacy : false,
        countryCode : "+91",
        jobDetails : jobDetails,
        comingFrom : "Career"
      });
      const [resumeUpload,setResumeUpload] = useState({})
    const resumeFileRef = useRef()
    
      const handleOnChange = (e) => {
        setData((preve) => {
          return {
            ...preve,
            [e.target.name]: e.target.value,
          };
        });
      };
    
      const handleSubmit = async(e) => {
        e.preventDefault();
        // console.log("data - ",data);
        const {name, email,mobile,file} = data 

        if(name && email && mobile){
            if(file){
                onClick()
                const res =await axios.post(config.applyjob.url,data)
                // console.log(res.data.message)
                if(res.data.error){
                  toast.error(res.data.message)
                }else{
                  toast.success(res.data.message)
                }
            }else{
              toast.error("Please upload Resume...!")
            }
        }else{
          toast.error("Enter the required fields...!")
        }
      };

      const handleUploadResume = () => {
        resumeFileRef.current.click()
        console.log(resumeFileRef)
        
      }

      const [loadingPDF,setLoadingPDF] = useState(false)
      const handleOnChangeUpload = async(file)=>{
            console.log(file.target.files[0])
            setResumeUpload(file.target.files[0])
            const formData = new FormData()
            formData.append("file",file.target.files[0])
            setLoadingPDF(true)
            const res = await axios.post(config.upload.UploadFile,formData)
            // console.log(res)
            setLoadingPDF(false)
            setData((preve)=>{
                return{
                    ...preve,
                    file : res.data.data.url
                }
            })  
      }
      


      return (
        <div className="bg-white p-4 lg:p-5 box-border rounded-lg ">
          <div className="ml-auto w-fit ">
            <span
              className="text-xl text-slate-400 cursor-pointer"
              onClick={onClick}
            >
              <GrClose />
            </span>
          </div>
          <h2 className="text-lg lg:text-xl  text-center font-extrabold">
            Apply Now
          </h2>
          <div className="px-10 p-0.5 max-w-[100px] rounded-full bg-blue-800 mx-auto mb-7 mt-2"></div>
          <form
            className="max-w-sm flex  flex-col lg:max-w-md lg:min-w-[400px] flex-wrap gap-5 w-full m-auto min-w-[300px]"
            onSubmit={handleSubmit}
          >
            <ThemeProvider theme={theme}>
              <TextField
                type="text"
                id="name"
                label="Name"
                variant="outlined"
                className="w-full outline-red-800 border-red-800 hover:outline-blue-600"
                size="small"
                color="primary"
                name="name"
                onChange={handleOnChange}
                required
              />
    
              {/* <TextField
                type="text"
                id="text-basic"
                label="Mobile"
                variant="outlined"
                className="w-full outline-red-800 border-red-800 hover:outline-blue-600"
                size="small"
                name="mobile"
                onChange={handleOnChange}
                required
              /> */}
               <div className="flex items-center gap-3 w-full">
          <FormControl required sx={{ minWidth: 90, position: "relative", width : "" }}>
            <InputLabel id="demo-simple-select-required-label" size="small">
              code
            </InputLabel>
            <Select
              labelId="countryCode"
              id="countryCode"
              name="countryCode"
              value={data.countryCode}
              label="code"
              size="small"
              onChange={handleOnChange}
            >
              {
                countryCode.map((el,index)=>{
                  return(
                    <MenuItem value={"+"+el.code}>+{el.code} {el.country}</MenuItem>
                  )
                })
              }
            </Select>
            {/* <FormHelperText>Required</FormHelperText> */}
          </FormControl>

          <TextField
            type="number"
            id="text-basic"
            label="Mobile"
            variant="outlined"
            className="w-full outline-red-800 border-red-800 hover:outline-blue-600"
            size="small"
            name="mobile"
            onChange={(e)=>{
              setData((preve) => {
                return {
                  ...preve,
                  mobile : parseInt(e.target.value),
                };
              });
            }}
            inputProps={{ maxlength : "10", size : "10" }}
            required
          />
          </div>

            <TextField
                type="email"
                id="text-basic"
                label="Email"
                variant="outlined"
                className="w-full outline-red-800 border-red-800 hover:outline-blue-600"
                size="small"
                name="email"
                onChange={handleOnChange}
                required
            />

            <div className="flex gap-3 items-center ">
                <input 
                    type="file"
                    className="bg-blue-900 text-white hidden"
                    ref={resumeFileRef}
                    onChange={handleOnChangeUpload}
                    name="file"
                    accept="application/pdf"
                />
                <div className="flex items-center py-2 gap-1 border border-blue-900 px-3 bg-blue-100 text-blue-900 font-bold rounded-full w-40 cursor-pointer whitespace-nowrap" onClick={handleUploadResume}>
                    <span className="text-xl text-blue-900"><IoMdCloudUpload/></span>
                    <span className="text-sm">Upload Resume</span>
                </div>
                {resumeUpload.name && <p>{resumeUpload.name}</p>}
            </div>

            <FormGroup >
                <FormControlLabel  control={
                <>
                    <Checkbox name="dataPrivacy" id="dataPrivacy" onChange={(e)=>{
                  setData((preve)=>{
                    return{
                      ...preve,
                      dataPrivacy : e.target.checked
                    }
                  })
                }}  required/>
                <Typography component={'label'} id="dataPrivacy" fontSize={14} sx={{display : "flex", flexWrap : 'wrap', alignItems : "center", whiteSpace : 'nowrap', margin : '0' }}>
                  You can connect me Via call, on <span className="mx-1 bg-green-600 text-white rounded-full text-lg"><FaWhatsapp/></span> or email.
                </Typography>
              </>
              
              }  
                className="formCheckboxLable text-gray-800" />
          </FormGroup>
    
              
            </ThemeProvider>
            <div className="block mt-4">
            <button className="px-4 py-2 bg-blue-900 text-white font-medium tracking-wide rounded mx-auto block">
              Submit
            </button>
            </div>
          </form>

              {
                loadingPDF && (
                  <div className="fixed top-0 bottom-0 right-0 left-0 flex justify-center items-center bg-slate-200 bg-opacity-30 z-50">
                  <CircularLoading/>
                </div>
                )
              }
        </div>
        )       
      
}

export default ApplyForm