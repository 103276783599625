import React from 'react'
import image1 from '../../assets/developer/Lodha.png'
import image2 from '../../assets/developer/Prestige.png'
import image3 from '../../assets/developer/adiryabirlagroup.png'
import image4 from '../../assets/developer/hiranandani.png'
import image5 from '../../assets/developer/oberoi.png'
import image6 from '../../assets/developer/piramal.png'
import image7 from '../../assets/developer/runwal.png'
import image8 from '../../assets/developer/rustomjee.png'
import TooltipCustom from '../services/Tooltip'

import image9 from "../../assets/developer/abhinadan lodha.webp"
import image10 from '../../assets/developer/arihant.webp'
import image11 from '../../assets/developer/Balaji.webp'
import image12 from '../../assets/developer/dosti.webp'
import image13 from '../../assets/developer/godrej.webp'
import image14 from '../../assets/developer/kalpatru.webp'
import image15 from '../../assets/developer/shapoorji.webp'



const OurDeveloper = () => {
  const developerDataApi  = [
    { 
      id : "",
      image : image1,
      name : "Lodha"
    },
    { 
      id : "",
      image : image2,
      name : "Prestige"
    },
    { 
      id : "",
      image : image3,
      name : "Aditya Birla Group"
    },
    { 
      id : "",
      image : image4,
      name : "Hiranandan",
    },
    // { 
    //   id : "",
    //   image : image5,
    //   name : "Oberoi",
    // },
    { 
      id : "",
      image : image6,
      name : "Piramal",
    },
    { 
      id : "",
      image : image7,
      name : "Runwal",
    },
    { 
      id : "",
      image : image8,
      name : "Rustomjee",
    },

    { 
      id : "",
      image : image9,
      name : "Abhinandan Lodha",
    },

    { 
      id : "",
      image : image10,
      name : "Arihant",
    },

    { 
      id : "",
      image : image11,
      name : "Balaji",
    },
    { 
      id : "",
      image : image12,
      name : "Dosti",
    },

    { 
      id : "",
      image : image13,
      name : "Godrej",
    },

    { 
      id : "",
      image : image14,
      name : "Kalpatru",
    },

    { 
      id : "",
      image : image15,
      name : "Shapoorji",
    },
  ]

  const developerData = [
    ...developerDataApi,
    ...developerDataApi,
    ...developerDataApi,
    ...developerDataApi,
    ...developerDataApi,
    ...developerDataApi,
    ...developerDataApi,
    ...developerDataApi,
    ...developerDataApi,
    ...developerDataApi,
    ...developerDataApi,
    ...developerDataApi,
    ...developerDataApi,
    ...developerDataApi,
    ...developerDataApi,
    ...developerDataApi
  ]
  return (
    <section id='OurDeveloper' className=''>
      <div className='container m-auto my-16 px-4 '>
          <h2 className='text-2xl lg:text-3xl font-medium text-center'>Featured <span className='font-bold'>Developers</span></h2>
          <div className='px-10 p-0.5 max-w-[100px] rounded-full bg-blue-800 m-auto mt-2'></div>

          <div className='flex gap-8 my-10 overflow-hidden scrollbar-none developer '>
            {
              developerData.map((el,index) => {
                return(
                  <div key={el.id} className={`developerLogo min-w-[125px] min-h-[80px]  bg-white w-full rounded flex justify-center items-center border-2 px-3 ${el.image ? "" : "animate-pulse bg-slate-100"}`}>
                    <TooltipCustom title={el.name}>
                        { el.image && <img src={el.image} className='object-fit w-full mix-blend-multiply' alt='ourdeveloper'/> }
                    </TooltipCustom>
                    
                  </div>
                )
              })
            }
          </div>
      </div>
    </section>
  )
}

export default OurDeveloper