import React, { useState } from 'react'
import Hero from '../components/hero/Hero'
import OurDeveloper from '../components/ourDeveloper/OurDeveloper'
import OurServices from '../components/ourServices/OurServices'
import OurJourney from '../components/ourJourney/OurJourney'
import OurTeams from '../components/ourTeams/OurTeams'
import Testomonials from '../components/Testomonials/Testomonials'
import Contactus from '../components/contactus/Contactus'
import Aboutus from '../components/about/Aboutus'
import {
  FaUserTie,
  FaWhatsapp
} from "react-icons/fa";
import Model from '../components/services/Model'
import EnquiryForm from '../components/enquiryForm/EnquiryForm'
import OurPresence from '../components/ourPresence/OurPresence'
import { HiUserGroup } from 'react-icons/hi'


const Home = () => {
  const [openEnquiryForm,setopenEnquiryForm] = useState(false)
  // href='https://wa.me/+919324900070'

  const closeForm = (e)=>{
    e.stopPropagation();
    setopenEnquiryForm(false)
   
  }


  const connectData =  {
        title: "Profiling & shortlisting",
        desc: `  Our experts work very closely with the customer to find the right property.`,
        icon: <HiUserGroup />,
        button : "Connect with Expert",
        modelTitle : "Connect with House Seekers",
        required :   {
          name : true,
          countryCode : true,
          mobile : true,
          email : true,
          propertyType : false,
          occupationType : false,
          location : false,
          locationofProperty : false,
          address : false,
          SourceAddress : false,
          destinationAddress : false,
          dateTimePicker : false,
          dataPrivacy : true,
        },
        EnquiryFormButton : "Connect with Expert",
        display : {
          address : false
        },
        
  }
  return (
    <>
      <Hero/>
      <OurDeveloper/>
      <OurServices openForm={()=>setopenEnquiryForm(true)}/>
      {/* <OurJourney/>*/}
      <Testomonials/>
      <Aboutus/>
      <OurPresence/>
      {/* <OurTeams/>  */}

       { openEnquiryForm && 
        <Model onClose={closeForm}>
        <EnquiryForm 
          onClick={closeForm}
          heading={"Connect with our property expert!"}
          PropertyType={connectData.PropertyType || []}
          location={connectData.location || false}
          OccupationType={connectData.OccupationType || []}
          ToFrom={connectData.ToFrom || false}
          required={connectData.required || {}}
          address={false}
          comingFrom="Property Expert"
        />
       </Model>
       }  

      <div className='px-4 mx-auto text-center  py-16 bg-gradient-to-r from-orange-500 to-yellow-500 p-1 rounded'>
          <div className='text-2xl border-2 border-black p-1 rounded-full w-14 h-14 flex justify-center items-center m-auto mb-2'><FaUserTie/></div>
          <div className='text-2xl font-extrabold text-center'>Connect with our property expert!</div>
          <p className='text-center my-1'>Get dedicated relationship manager for your property needs.</p>

          <button onClick={()=>setopenEnquiryForm(true)} className='bg-white  px-5 font-bold py-2 rounded flex gap-2 max-w-[160px] text-xl items-center justify-center mx-auto mt-4'>
            Connect
          </button>
      </div>
    </>
  )
}

export default Home