import React, { lazy, useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { AiOutlineMenu } from "react-icons/ai";
import useToggle from "../../hooks/useToggle";
import { ImPhone } from "react-icons/im";

const Logo = lazy(() => import("./Logo"));

const navigation = [
  {
    id: "home",
    name: "Home",
    to: "",
  },
  {
    id: "Career",
    name: "Career",
    to: "/career",
  },
  {
    id: "ourLeader",
    name: "Our Leaders",
    to: "/our-leader",
  },
  {
    id: "gallery",
    name: "Gallery",
    to: "/gallery",
  },
  {
    id: "contact",
    name: "Contact Us",
    to: "/contact",
  },
];

const Header = () => {
  const [menuToggle, setMenuToggle] = useToggle();
  const [scroll, setScroll] = useState(0);
  const [translatePhone, setTranslatePhone] = useState(false);

  const toggleMenuBtn = () => {
    setMenuToggle();
  };

  useEffect(() => {
    window.addEventListener("scroll", (e) => {
      setScroll(window.scrollY);
    });
  }, [window.screenY]);

  console.log(scroll);
  return (
    <>
      <header
        className={`h-20 fixed w-full z-50 bg-white ${
          scroll > 80 ? "shadow" : ""
        }`}
      >
        <div className="container m-auto flex justify-between h-full px-3">
          <Logo />
          <nav className="hidden md:flex items-center gap-4  text-slate-900">
            <div className="w-32  overflow-hidden">
              <p
                className={`text-sm transition-all text-blue-900 font-semibold ${
                  translatePhone ? "translate-x-0" : "translate-x-32"
                }`}
              >
                +91 9324900070
              </p>
            </div>
            <div
              className="bg-blue-100 cursor-pointer text-blue-900 text-base p-2 rounded-full border border-blue-900"
              onClick={() => setTranslatePhone((preve) => !preve)}
            >
              {/* <a href="tel://+919324900070" > */}
              <ImPhone />
              {/* </a> */}
            </div>
            {navigation.map((el) => (
              <NavLink
                to={`${el.to}`}
                className={({ isActive }) =>
                  `${
                    isActive ? "text-blue-800  font-medium" : ""
                  } hover:text-blue-800`
                }
              >
                {el.name}
              </NavLink>
            ))}
          </nav>

          <div className="items-center gap-4 w-fit relative justify-center flex md:hidden">
            <div className="bg-blue-100 text-blue-900 text-base p-1.5 rounded-full border border-blue-900 flex md:hidden items-center justify-center">
              <a href="tel://+919324900070">
                <ImPhone />
              </a>
            </div>
            <div
              className="flex md:hidden flex-col gap-1 justify-center items-center relative"
              onClick={toggleMenuBtn}
            >
              <div
                className={`w-6 h-1 bg-slate-900 rounded-full transition-all duration-300 ${
                  menuToggle ? "rotate-45 absolute " : ""
                }`}
              ></div>
              {!menuToggle && (
                <div className="w-6 h-1 bg-slate-900 rounded-full transition-all duration-300"></div>
              )}
              <div
                className={`w-6 h-1 bg-slate-900 rounded-full transition-all duration-300  ${
                  menuToggle ? "-rotate-45 " : ""
                }`}
              ></div>
            </div>
          </div>
        </div>
      </header>

      {
        <aside
          className={`fixed flex md:hidden w-full top-20 min-h-[calc(100vh-80px)] z-50 max-w-screen-md transition-all duration-150 ${
            menuToggle ? "" : "-translate-x-full"
          }`}
        >
          <nav className="flex flex-col gap-3  relative pt-9 items-center  font-medium text-slate-900 min-w-[250px] bg-white">
            {navigation.map((el) => (
              <NavLink
                to={`${el.to}`}
                className={({ isActive }) =>
                  `${
                    isActive ? "text-blue-800 border-b-blue-800 font-bold" : ""
                  }
                          border-4 pb-2 mb-2 flex items-center px-2 border-transparent
                          hover:text-blue-800
                          transition-all 
                        `
                }
                onClick={toggleMenuBtn}
              >
                {el.name}
              </NavLink>
            ))}
          </nav>
          <div
            className="w-full bg-slate-800 opacity-50 min-w-min"
            onClick={toggleMenuBtn}
          ></div>
        </aside>
      }
    </>
  );
};

export default Header;
