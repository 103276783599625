import React, { useCallback, useEffect, useState } from "react";
import CareerBanner from "../assets/career/career-banner.jpg";
import { GiPayMoney, GiMoneyStack, GiStairsGoal, GiCash } from "react-icons/gi";
import { FaGift } from "react-icons/fa";
import { BsCashCoin } from "react-icons/bs";
import { HiMiniGlobeAlt } from "react-icons/hi2";
import { MdHealthAndSafety ,MdLeaderboard} from "react-icons/md";
import { PiConfettiFill } from "react-icons/pi";
import { GiTakeMyMoney } from "react-icons/gi";
import { FaAngleDown,FaAngleRight } from "react-icons/fa6";
import {FaBalanceScale} from 'react-icons/fa'
import CareerCard from "../components/careers/CareerCard";
import axios from 'axios'
import config from '../config'
import CircularLoading from "../components/Loading/CircularLoading";
import { CircularProgress, LinearProgress } from "@mui/material";
import { Loader } from "react-feather";
import {SiLeaderprice} from 'react-icons/si'
 
const benefitandParks = [
  {
    icons: <GiCash />,
    title: `Highly Competitive Compensation`,
  },
  {
    icons: <GiStairsGoal />,
    title: `Supersonic Growth`,
  },
  {
    icons: <FaGift />,
    title: `Best Incentive Structure`,
  },
  {
    icons: <BsCashCoin />,
    title: `Bi-Annual Appraisals for Sales`,
  },
  {
    icons: <MdLeaderboard />,
    title: `Opportunity to Become Leader`,
  },
  {
    icons: <GiTakeMyMoney />,
    title: `Employee Stock Options`,
  },
  {
    icons: <FaBalanceScale />,
    title: `All Financials Perks on Time`,
  },
  {
    icons: <PiConfettiFill />,
    title: `Fun, Dynamic Environment`,
  },
];

const Career = () => {
  const [filterDataValue, setFilterDataValue] = useState([]);
  const [activeFilter,setActiveFilter] = useState("sales")
  const [fetchData,setfetchData]=useState([]);
  const [loading,setLoading] = useState(false)

  const FetchDataAPI= async()=>{
    setLoading(true)
    const res= await axios.get(config.career.url)
    await setfetchData(res.data.data)
    await setFilterDataValue(res.data.data)
    setLoading(false)
  }
  
  useEffect(() => {
        FetchDataAPI()
        // setActiveFilter("sales")
        // handleActive("sales");
  }, []);

  const handleActive = useCallback((filterValue) => {
    const filterData = fetchData.filter(
      (el) => el?.department?.toLowerCase() === filterValue?.toLowerCase()
    );
    setActiveFilter(filterValue)
    setFilterDataValue(filterData);
  },[activeFilter,fetchData,filterDataValue]);


  useEffect(()=>{
    handleActive([...new Set(fetchData?.map((el) => el?.department))][0])
  },[fetchData])


  return (
    <>
      <section className="">
        <div className="career-banner h-fit bg-slate-100">
          {
             !CareerBanner ? (
                <div className="h-54 w-full flex justify-center items-center text-blue-800">
                  <CircularProgress color="primary" size={80} Type="primary"/>
                </div>
             )
             :
             (
              <img src={CareerBanner} className="w-full" loading="lazy" />
             )
          }
          
        </div>

        <div className="container mx-auto my-16 px-4">
          <h2 className="text-2xl lg:text-3xl font-medium text-center ">
            <span className="font-extrabold">Benefits</span> And{" "}
            <span className="font-extrabold">Perks</span>
          </h2>
          <div className="px-10 p-0.5 max-w-[100px] rounded-full bg-blue-800 m-auto mt-2"></div>
          <p className="text-lg text-center max-w-sm md:max-w-md lg:max-w-lg xl:max-w-2xl mx-auto my-7">
            We are of a strong opinion that our employees are the architects of
            our success and growth. We thus leave no stone unturned to extend
            our gratitude and appreciation to them.
          </p>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 ">
            {benefitandParks.map((el, index) => {
              return (
                <div className="flex items-center gap-2 lg:gap-4 py-5 px-4 my-5 mx-2 shadow-lg">
                  <div className="icon text-4xl text-blue-900">{el.icons}</div>
                  <div className="max-w-[180px] font-bold">{el.title}</div>
                </div>
              );
            })}
          </div>

          
        </div>
      </section>

      <section>
      <div className="container  mx-auto my-16 px-4">
            <h2 className="text-2xl lg:text-3xl font-bold text-center ">
                Job Openings
            </h2>
          <div className="px-10 p-0.5 max-w-[100px] rounded-full bg-blue-800 m-auto mt-2  flex flex-col mb-9 "></div>
            {
                loading ? (
                  <div className="h-54 w-full flex justify-center items-center text-blue-800">
                    <CircularProgress color="primary" size={80} Type="primary"/>
                  </div>
                ) : (
              <div className="lg:flex lg:gap-5">
                  <div className="lg:min-w-[300px]">
                    <h2 className="text-xl font-bold text-slate-800">Departments</h2>
                    <hr />
                    <div className="flex items-center flex-row lg:flex-col gap-5 lg:gap-2 overflow-scroll scrollbar-none py-1">
                      {[...new Set(fetchData.map((el) => el.department))].map(
                        (el,index) => {
                          return (
                            <div
                              className={`px-3 py-1 cursor-pointer ${activeFilter?.toLowerCase() === el?.toLowerCase() && "bg-blue-100"} rounded-full lg:w-full font-bold flex items-center justify-between`}
                              onClick={() => handleActive(el)}
                            >
                              <p className="capitalize">{el} ({fetchData.filter(value => value?.department?.toLowerCase() === el?.toLowerCase()).length})</p>
                              <p className="text-sm font-normal hidden lg:block"><FaAngleRight/></p>
                            </div>
                          );
                        }
                      )}
                    </div>
                    <hr />
                  </div>
                  <div className="w-full">
                    <ul className="flex flex-col gap-5 mt-5 w-full min-h-[200px]">
                      {filterDataValue.map((el, index) => {
                        return (
                          <CareerCard el={el}/>
                        );
                      })}
                    </ul>
                  </div>
            </div>
                )
            }
          </div>
      </section>
    </>
  );
};

export default Career;
