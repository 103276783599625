import React from 'react'
import thane from '../../assets/location/mumbai.png'
import Andheri from "../../assets/location/Andheri.png"
import pune from "../../assets/location/pune.png"
import bangalore from "../../assets/location/Bangalore.png"
import delhi from "../../assets/location/delhi.png"
import TooltipCustom from '../services/Tooltip'

const OurPresence = () => {
  
    const ourPresentData = [
        {
            icon : thane,
            name : "Mumbai"
        },
        {
            icon : pune,
            name : "Pune",
        },
        {
            icon : bangalore,
            name : "Bangalore",
        },
        {
            icon : delhi,
            name : "NCR",
        }
    ]

  return (
    <section id="ourPresent" className=" p-1">
      <div className="container m-auto my-16 px-4 ">
        <h2 className="text-2xl lg:text-3xl font-medium text-center">
          Our <span className="font-extrabold">Presence</span>
        </h2>
        <div className="px-10 p-0.5 max-w-[100px] rounded-full bg-blue-800 m-auto mt-2"></div>

        <div className='flex  flex-wrap mt-14 xl:gap-[62px] lg:gap-14 md:gap-12 sm:gap-10 gap-7 justify-center'>
        {
                ourPresentData.map((el,index)=>{
                    return(
                        <div className='bg-white min-w-[200px] min-h-[200px] rounded shadowClass  flex justify-center items-center flex-col'>
                            <div className=' w-20 h-24 lg:w-24 flex justify-center items-center'>
                                <TooltipCustom title={el.name}>
                                        <img src={el.icon} className='w-full h-full'/>
                                </TooltipCustom>
                            </div>
                            <p className='my-2'>{el.name}</p>    
                        </div>
                    )
                })
            }
        </div>


      </div>
    </section>
  )
}

export default OurPresence