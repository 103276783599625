import "./App.css";
import Header from "./components/header/Header";
import { Outlet, useParams } from "react-router-dom";
import Footer from "./components/footer/Footer";
import { useEffect, useState } from "react";
import ScrollToTop from "./helper/ScrollToTop"
import ChatbotIcon from "./components/chatbot/ChatbotIcon";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import config from "./config";

const theme = createTheme({
  palette: {
    primary: {
      main: "#1e40af",
    },
    danger : {
      light: '#ef5350',
      main: "#d32f2f",
      dark: '#c62828',
      contrastText: '#fff',
    }
  },
});



function App() {
  const params = useParams()


  useEffect(()=>{
    console.log("HouseSeekers....!")
  },[params])

  useEffect(()=>{
    (
      async()=>{
        const data = await axios.get(config.galleryImageCategory.url)
        console.log(data.data)
      }
    )()
  },[])
  useEffect(()=>{
    (
      async()=>{
        const data = await axios.get(config.gallerySubCategory.url)
        console.log(data.data)
      }
    )()
  },[])
  useEffect(()=>{
    (
      async()=>{
        const data = await axios.get(config.galleryImage.url)
        console.log(data.data)
      }
    )()
  },[])

  return (
    <>
      <ThemeProvider theme={theme}>
      <ToastContainer/>
      <ScrollToTop/>
      {/*<ChatbotIcon/>*/}
      <Header />
      <main className="pt-20 overflow-hidden">
        <Outlet />
      </main>
      <Footer />
      </ThemeProvider>
    </>
  );
}

export default App;
