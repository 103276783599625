import { createBrowserRouter, createRoutesFromElements, Route } from "react-router-dom";
import App from "../App";
import Home from "../pages/Home";
import Career from "../pages/Career";
import About from "../pages/About";
import Contact from "../pages/Contact";
import Service from "../pages/Service";
import { OurTeams } from "../pages/OurTeams";
import Gallery from "../pages/Gallery";
import EventList from "../components/gallery/EventList";
import EventImage from "../components/gallery/EventImage";
import EventYears from "../components/gallery/EventYears";
import PrivacyPolicy from "../pages/PrivacyPolicy";

const router = createBrowserRouter(
    createRoutesFromElements(
        <Route path="/" element={<App/>}>
            <Route path="" element={<Home/>}/>
            <Route path="home" element={<Home/>}/>
            <Route path="about" element={<About/>}/>
            <Route path="contact" element={<Contact/>}/>
            <Route path="service" element={<Service/>}/>
            <Route path="career" element={<Career/>}/>
            <Route path="our-leader" element={<OurTeams/>}/>
            <Route path="gallery" element={<Gallery/>}>
                <Route path="" element={<EventList/>}/>
                <Route path=":imageCategory" element={<EventYears/>}></Route>
                <Route path=":imageCategory/:subCategory" element={<EventImage/>}/>
            </Route>
            <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>
        </Route>
    )
)

export default router