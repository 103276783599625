import React from 'react'
import Contactus from '../components/contactus/Contactus'
import OurPresence from '../components/ourPresence/OurPresence'

const Contact = () => {
  return (
    <>
      <Contactus/>
     
    </>
  )
}

export default Contact