import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import config from '../../config'
import { CircularProgress, LinearProgress,Breadcrumbs, } from "@mui/material";
import {LiaAngleRightSolid} from 'react-icons/lia'
import HomeIcon from '@mui/icons-material/Home';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import GrainIcon from '@mui/icons-material/Grain';
import Typography from '@mui/material/Typography';

const EventList = () => {
    // const numberEventList = useSelector(state => state)
        const [loading,setLoading] = useState(false)
        const [getData,setGetData] = useState([])
        const params = useParams()
        const [subCategoryLen,setSubCategoryLen] = useState([])
        
        const fetchData = async () => {
          setLoading(true)
          const res = await fetch(config.gallery.imageCategory.URL);
          const dataResponse = await res.json();
          setGetData(dataResponse?.data);
          console.log("data response",dataResponse.data)
          setLoading(false)
      };
      useEffect(()=>{
          fetchData()
      },[])

      const fetchDataSubCategory = async () => {
        setLoading(true)
        const res = await fetch(config.gallery.subCategoryImage.URL);
        const dataResponse = await res.json();
        setSubCategoryLen(dataResponse.data)
        setLoading(false)
    };
    useEffect(()=>{
      fetchDataSubCategory()
    },[])


  return (
    <>
    <div className="container mx-auto my-10">
        <h2 className="text-2xl lg:text-3xl font-extrabold text-center mt-3">
          Our Culture
        </h2>
        <div className="px-10 p-0.5 max-w-[100px] rounded-full bg-blue-800 mt-2 mb-12 mx-auto"></div>
        
       
          <div className="capitalize px-2 my-1 font-bold">
             <div role="presentation">
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                underline="hover"
                sx={{ display: 'flex', alignItems: 'center' }}
                color="inherit"
                to="/gallery"
              >
                <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                Gallery
              </Link>
            </Breadcrumbs>
          </div>
          </div> 
        
        <div className="my-5">
          
                    `    <div className='flex  flex-wrap gap-4 md:gap-9 justify-center'>
                    { !loading && ( 
                    getData.map(el =>{
                        console.log(el)
                        return(
                            <Link to={el._id} className=' w-52 min-h-[120px]  flex-col flex justify-center items-center rounded font-bold relative'>
                            { el?.thumnail ?  <img src={el?.thumnail} className='shadow-xl w-full h-full bg-opacity-50' loading='lazy'/> : <div className='bg-slate-300 shadow-xl min-h-[120px]'></div> }  
                                <p className='capitalize pt-2'>{el?.categoryImage} ({subCategoryLen.filter(el1 => el1?.category?._id == el?._id ).length})</p>
                            </Link>
                        )
                        }))
                    }

                    {   loading && (
                            <div className="h-54 w-full flex justify-center items-center text-blue-800">
                            <CircularProgress color="primary" size={80} Type="primary"/>
                        </div>
                        )    
                    }
                    </div>`

        </div>
      </div>
    </>
    
  )
}

export default EventList

