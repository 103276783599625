import React, { useEffect, useState } from "react";
import thane from "../assets/location/mumbai.png";
import Andheri from "../assets/location/Andheri.png";
import pune from "../assets/location/pune.png";
import bangalore from "../assets/location/Bangalore.png";
import delhi from "../assets/location/delhi.png";
import { Link, Outlet, useParams } from "react-router-dom";
import { FaAngleRight } from "react-icons/fa";
import { LiaAngleRightSolid } from "react-icons/lia";
// import TooltipCustom from '../services/Tooltip'
import config from '../config'
import { CircularProgress, LinearProgress } from "@mui/material";

const Gallery = () => {
  const params = useParams();
  console.log(params);

  const [loading,setLoading] = useState(false)
  const [getData,setGetData] = useState([])

  const fetchData = async () => {
    setLoading(true)
    const res = await fetch(config.gallery.imageCategory.URL);
    const dataResponse = await res.json();
    setGetData(dataResponse?.data);
    console.log("data response",dataResponse.data)
    setLoading(false)
};
useEffect(()=>{
    fetchData()
},[])

  console.log("Gallery Page",params)


  return (
    <section>
      <Outlet />
    </section>
  );
};

export default Gallery;



        {/* <div className="capitalize py-1 bg-slate-100 px-2">
          <div className="flex items-center gap-3">
            <Link to={"/gallery"} className="">
              Gallery
            </Link>
            {params.eventYears && (
              <>
                <div className="">
                  <LiaAngleRightSolid />
                </div>
                <Link to={`/gallery/${params.eventYears}`} className="">
                  {params.eventYears}
                </Link>
              </>
            )}

            {params.eventImage && (
              <>
                <div className="">
                  <LiaAngleRightSolid />
                </div>
                <Link to={`/gallery/${params.eventImage}`} className="">
                  {params.eventImage}
                </Link>
              </>
            )}
          </div>
        </div> */}