import React, { useEffect,useState } from "react";
import sampleImage from "../../assets/ourTeams/ourTeams.png";
import { BsLinkedin } from "react-icons/bs";
import backgroundImage from "../../assets/ourTeams/background.jpg"
import axios from 'axios';
import config from "../../config";
import Model from "../services/Model";
import { GrClose } from "react-icons/gr";
import loadingImage from  '../../assets/Logo.png'

const OurTeams = () => {
  const [TeamsData,setTeamsData]=useState([]);

  const teamsData=()=>{
    const data= axios.get(config.teams.url)
    .then((res)=>setTeamsData(res.data.data))
  }
  useEffect(()=>{
    teamsData()
  },[])

  const [activeTeams,setActiveTeams] = useState(false)
  const [activeTeamsData,setActiveTeamsData] = useState({})
  const handleOnClick = (e)=>{
    setActiveTeamsData(e)
    setActiveTeams(true)
  }
  const handleCloseModel = ()=>{
    setActiveTeamsData({})
    setActiveTeams(false)
  }
  return (
    <section id="ourTeams" className="p-1 relative w-full h-full ">
      <div className="container   mx-auto my-10 relative z-10">
        <h2 className="text-2xl lg:text-3xl font-medium text-center">
          Our <span className="font-extrabold">Leaders</span>
        </h2>
        <div className="px-10 p-0.5 max-w-[100px] rounded-full bg-blue-800 m-auto mt-2"></div>

        <div className="grid ourTeamsContainer mt-16 gap-5 md:gap-7 justify-center">
          {
            TeamsData[0] ? (
              TeamsData.map((el, index) => {
                return (
                  <div className="flex justify-center items-center flex-col max-w-xs relative mx-auto " onClick={()=>handleOnClick(el)}>
                    <div className="h-56 w-56 rounded-full overflow-hidden relative shadow-2xl flex justify-center items-start">
                      <img src={el.image || sampleImage} alt="our teams" className="transition-all hover:scale-110 min-w-[90px] min-h-[90px]" loading='lazy'/>
                    </div>
                    <div className="relative  flex flex-col items-center justify-between w-full my-3 text-lg text-black font-bold">
                      <div className="text-xl lg:text-2xl">{el.name}</div>
                      <p className="font-medium">{el.position}</p>
                      <div className="text-blue-900 text-2xl">
                      {
                        el.linkedin && (
                          <a href={el.linkedin}>
                               <BsLinkedin />
                          </a>
                        )
                      }
                      </div>
                    </div>
                </div>
            )})) 
            :
            (
              Array.from("Teams123").map(el => {
                return(
                  <div className="flex justify-center items-center flex-col max-w-xs relative mx-auto">
                    <div className="h-56 w-56 rounded-full overflow-hidden relative p-2 bg-blue-100 animate-pulse shadow-md min-w-[224px]  min-h-[224px] flex justify-center items-center">
                      {/* <img src={loadingImage} alt="our teams" className="transition-all  min-w-[140px] min-h-[60px] box-border grayscale  block"/> */}
                      <p>Houseseekers</p>
                    </div>
                    <div className="relative  flex flex-col items-center justify-between w-full my-3 text-lg text-black font-bold">
                      <div className="text-xl lg:text-2xl bg-blue-100 animate-pulse shadow-md p-2 min-w-[90px] m-1 rounded"></div>
                      <p className="font-medium bg-blue-100 animate-pulse shadow-md p-2 min-w-[90px] m-1 rounded"></p>
                      <div className="text-blue-900 text-2xl bg-blue-100 animate-pulse shadow-md p-3 min-w-[90px] m-1 rounded">
                      </div>
                    </div>
                  </div>
                )
              })
            )
          }
        </div>
      </div>

      <div className="absolute top-0 bottom-0 w-full h-full opacity-40">
        <img src={backgroundImage} alt="background" className="w-full h-full"/>
      </div>

      {
        activeTeams && (
          <div className="block md:hidden">
              <Model
                onClose={handleCloseModel}
              >
                <div className="bg-white w-full max-w-sm p-4 pt-2 rounded shadow-2xl">
                <div className="ml-auto w-fit relative pb-2">
                  <span
                    className="text-xl text-slate-400 cursor-pointer"
                    onClick={handleCloseModel}
                  >
                    <GrClose />
                  </span>
                </div>
                <div className="h-64 w-64  overflow-hidden relative flex justify-center items-start">
                      <img src={activeTeamsData.image || sampleImage} alt="our teams" className="transition-all  min-w-[90px] min-h-[90px]"/>
                </div>
                <div className="relative  flex flex-col items-center justify-between w-full my-3 text-lg text-black font-bold">
                      <div className="text-xl lg:text-2xl">{activeTeamsData.name}</div>
                      <p className="font-medium">{activeTeamsData.position}</p>
                      <div className="text-blue-900 text-2xl">
                      {
                        activeTeamsData.linkedin && (
                          <a href={activeTeamsData.linkedin}>
                               <BsLinkedin />
                          </a>
                        )
                      }
                      </div>
                </div>
                </div>
              </Model>
          </div>
        )
      }    
     

    </section>
  );
};

export default OurTeams;
