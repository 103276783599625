import React from "react";
import {
  FaFacebook,
  FaInstagram,
  FaLinkedinIn,
  FaWhatsapp,
  FaYoutube,
  FaTwitter,
} from "react-icons/fa";
import { ImPhone } from "react-icons/im";
import { MdOutlineEmail } from "react-icons/md";
import { Link } from "react-router-dom";

const Footer = () => {
  //#1a1820
  return (
    <footer className="bg-black  text-white relative overflow-hidden z-10">
      <div className="container m-auto w-full py-14 px-4  z-10 relative">
        <div className="w-full m-auto flex flex-col-reverse lg:flex-row-reverse justify-between gap-6   ">
          <div className="flex-1">
            <h2 className="lg:text-center text-lg font-medium">Get in Touch</h2>
            <div className="px-10 p-0.5 max-w-[100px] rounded-full bg-gray-800 lg:m-auto mt-1 mb-7 lg:mb-7"></div>
            <ul className="flex gap-4 text-xl min-w-fit w-fit lg:m-auto text-gray-400">
              <li>
                <a
                  href="https://www.facebook.com/HouseSeekersIndia"
                  className="hover:text-blue-500"
                >
                  <FaFacebook />
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/houseseekers.in/"
                  className="hover:text-blue-500"
                >
                  <FaInstagram />
                </a>
              </li>
              <li>
                <a
                  href="https://www.youtube.com/@houseseekers6878"
                  className="hover:text-blue-500"
                >
                  <FaYoutube />
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/company/houseseekers/"
                  className="hover:text-blue-500"
                >
                  <FaLinkedinIn />
                </a>
              </li>
              <li>
                <a
                  href="https://twitter.com/houseseekersin"
                  className="hover:text-blue-500"
                >
                  <FaTwitter />
                </a>
              </li>
              <li>
                <a
                  href="https://wa.me/+919324900070"
                  className="hover:text-blue-500"
                >
                  <FaWhatsapp />
                </a>
              </li>
            </ul>
          </div>
          <div className=" flex-1 w-fit lg:mx-auto max-w-fit">
            <h3 className="text-lg font-medium">Connect with Us</h3>
            <div className="px-10 p-0.5 max-w-[100px] rounded-full bg-gray-800  mt-1 mb-7"></div>
            <ul className="text-left text-gray-400  text-sm w-fit lg:mx-auto flex flex-col gap-3">
              <li className=""><span className="font-semibold text-white">Write to us at </span><br/> <a href="mailto:hr@houseseekers.in" className=" text-sm">hr@houseseekers.in</a></li>
              <li className="font-medium"><span className="font-semibold text-white">Feedback</span> <br/>
              <a href="mailto:mukesh@houseseekers.in" className="text-sm" >mukesh@houseseekers.in</a>
              </li>
              <li className="font-medium">
                <div className="flex items-center font-semibold text-white">Call us or  <span className="px-2 text-lg text-green-600"><FaWhatsapp /></span></div>
                <p className=" text-sm">9324900070</p>
              </li>
            </ul>

          </div>
          <div className="flex-1">
            {/* <ul className="flex flex-col gap-3 text-sm">
              <li className="flex items-center gap-4">
                <div className="w-7 h-7 rounded-full flex justify-center items-center border border-white">
                  <ImPhone />
                </div>
                <div className="">+919324900070</div>
              </li>
              <li className="flex items-center gap-4">
                <div className="w-7 h-7 rounded-full flex justify-center items-center border border-white">
                  <MdOutlineEmail />
                </div>
                <div className="">mukesh@houseseekers.in</div>
              </li>
            </ul> */}
            <h3 className="text-lg font-medium text-left">Vision & Misison</h3>

            <div className="px-10 p-0.5 max-w-[100px] rounded-full bg-gray-800  mt-1 mb-7"></div>

            <h4 className="text-base font-medium text-left">Vision</h4>
            <p className="text-gray-400 text-sm max-w-sm">
            “We want to be the forerunner real estate consultant firm, who not only trade the properties but go beyond to make our customers smile broad.”
            </p>


            <h4 className="text-base font-medium text-left mt-2">Mission</h4>
            <p className="text-gray-400 text-sm max-w-sm">
              “We aim to nurture our customers by providing them with one-stop shop home buying services with transparency atop.”
            </p>

          </div>
        </div>
      </div>

      <hr className="bg-gray-800 border-gray-800 z-10 relative" />
        <p className="text-sm w-fit mx-auto pt-4">
          <Link to={"/career"} className="mx-1 hover:text-blue-400">Career</Link>
          {"|"}
          {/* <Link to={"/our-leader"} className="mx-1 hover:text-blue-400">Our Leaders</Link>
          {"|"}
          <Link to={"/gallery"} className="mx-1 hover:text-blue-400">Gallery</Link>
          {"|"} */}
            <Link to={"/privacy-policy"} className="mx-1 hover:text-blue-400">Privacy Policy</Link>
        </p>
      <p className="pb-4 w-fit m-auto z-10 relative text-center text-sm">
        <span>© Copyright <strong>House Seekers</strong>. </span>
        <span>All Rights Reserved.</span>
      </p>
    </footer>
    
  );
};

export default Footer;
