import { CircularProgress } from "@mui/material";
import React from "react";

const CircularLoading = () => {
  return (
    <div className="h-54 w-full flex justify-center items-center text-blue-800">
                    <CircularProgress color="primary" size={80} Type="primary"/>
    </div>
  );
};

export default CircularLoading;
